// @flow

import type { Fetch }            from "fetch-utilities";

import reducers                  from "reducers";
import thunk                     from "redux-thunk";
import trackedPromises           from "redux-enhancer-tracked-promises";
import { compose,
         createStore,
         applyMiddleware } from "redux";
import { routerMiddleware, LOCATION_CHANGE } from "react-router-redux";

import {
  LOADED  as CHECKOUT_VIEW,
  SUCCESS as CHECKOUT_SUCCESS }   from "store-reducers/checkout";
import {
  VIEW_LOADED as PRODUCT_LOADED } from "store-reducers/view";
import {
  ADDING   as CART_ADDING,
  REMOVING as CART_REMOVING }     from "store-reducers/cart";

import sideeffects from 'middlewares/sideeffects';

import enhanceWithGA, {
  modifyCart,
  purchaseSuccess,
  pageView,
  productView,
  checkout
} from "redux-enhancer-google-analytics";

import config from '../config'


export const mkStore = (state: Object, history: History, client: Fetch) =>
  createStore(reducers, state, compose(
    applyMiddleware(
      sideeffects,
      thunk.withExtraArgument(client),
      routerMiddleware(history)
    ),
    trackedPromises(),
    process.browser ? enhanceWithGA({
      accounts:     config.googleAnalytics[state.lang].accounts,
      currencyCode: config.googleAnalytics[state.lang].currencyCode,
      events:       {
        [LOCATION_CHANGE]  : pageView,
        [CHECKOUT_VIEW]    : checkout,
        [CHECKOUT_SUCCESS] : purchaseSuccess,
        [CART_ADDING]      : (ga, action) => { modifyCart(ga, action, "add") },
        [CART_REMOVING]    : (ga, action) => { modifyCart(ga, action, "remove") },
        [PRODUCT_LOADED]   : (ga, action) => {
          if (action.error || ! action.payload || action.payload.type !== "product") {
            return;
          }
          productView(ga, action);
        }
      }
    }) : f => f,
    process.env.NODE_ENV !== "production" && typeof window !== "undefined" && window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f,
  ));

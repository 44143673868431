// @flow @jsx h

import { h } from 'preact';
import { PureComponent } from 'shared-components';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import styles from './styles.scss';

type Props = {
  links: Array<{name: string, link: string}>,
  linksPerRow: number,
  linkClass: string,
  linkClick?: Function,
}

export default class LinkGrid extends PureComponent {

  handleClick = (e: SyntheticEvent<HTMLLinkElement>, name: string) => {
    if(typeof this.props.linkClick === 'function') {
      this.props.linkClick(name);
    }
  }

  render({links, linksPerRow = 3, linkClass, onClick}: Props) {
    const width = `calc(100%/${linksPerRow})`;
    return (
      <div class={cn(styles.linkGrid, this.props['class'])}>
        {links &&
          links.map(l => {
            return (
              <div
                class={cn(styles.link, linkClass)}
                style={{ width: width }}>
                <Link to={l.link} onClick={e => this.handleClick(e, l.name)}>{l.name}</Link>
              </div>
            );
          })
        }
      </div>
    );
  }
}

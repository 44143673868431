// @flow @jsx h

import { push } from "react-router-redux";
import { h } from 'preact';
import { PureComponent } from 'shared-components';
import { connect } from 'preact-redux';
import cn from 'classnames';
import Helmet from 'preact-helmet';

import {
  stutter,
  fadeIn, } from "helpers/animations";

import { LOADED as CUSTOMER_LOADED, NOT_FOUND as CUSTOMER_NOT_FOUND } from 'reducers/customer';

import { LOADED as ORDER_LOADED } from 'store-reducers/order';

import { empty as emptyCart } from 'store-reducers/cart';
import { terminate as terminateCheckout } from 'store-reducers/checkout';

import type { TOrder } from 'types/checkout';
import Infobar from 'components/Infobar';
import Wrapper from 'components/Wrapper';
import { ScrollTop } from 'shared-components';
import Button from 'components/Button';

import clipboard from 'helpers/clipboard';

import SuccessIcon from 'icons/success.svg';

import styles from './styles.scss';

type Props = {
  loaded: boolean,
  order: TOrder,
  emptyCart: Function,
  terminateCheckout: Function,
  push: Function,
  customerLoaded: boolean,
};

@connect(state => ({
  loaded: state.order.state === ORDER_LOADED,
  order: state.order.data,
  customerLoaded: state.customer.state === CUSTOMER_LOADED || state.customer.state === CUSTOMER_NOT_FOUND,
}),
dispatch => ({
  emptyCart: () => dispatch(emptyCart()),
  terminateCheckout: () => dispatch(terminateCheckout()),
  push: (u) => dispatch(push(u)),
}))
export default class CheckoutSuccess extends PureComponent {
  copyToClipboard = (e: SyntheticEvent<Element>) => {
    e.preventDefault();

    clipboard.copy(this.props.order.id);
  };

  handleButtonClick = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();

    this.props.push('/search');
  }

  componentWillUnmount() {
    this.props.emptyCart();
    this.props.terminateCheckout();
  }

  componentDidUpdate(prevProps: Props) {
    if (!this.props.customerLoaded) {
      this.props.push("/start");
    }
  }

  render({ loaded, order }: Props, _: any, { t, formatPrice }: Context) {
    const address = order.billingAddress;

    return (
      <div class={styles.checkoutSuccess}>

        <Infobar title={t('SUCCESS.TITLE')} />

        {loaded ?
          <Wrapper>
          <div class={styles.container}>
            <header class={styles.headerContainer}>
              <div
                style={stutter(1)}
                class={cn(styles.checkmark, fadeIn)}>

                <SuccessIcon
                  class={styles.successIcon}/>

              </div>

              <div>
                <h1 class={styles.header}>{t('SUCCESS.HEADING')}</h1>
              </div>
            </header>

            <section class={styles.info}>

              <div class="row">
                <div class={styles.row}>
                  <h3>{t('SUCCESS.ORDERNUMBER')}</h3>
                  <div class={styles.orderNumberContainer}>
                    <span
                      ref={node =>  this.orderNumber = node}>
                      {order.id}
                    </span>
                    <Button
                      title={t('SUCCESS.COPY')}
                      class={styles.copyButton}
                      onClick={this.copyToClipboard}
                      icon="copy"/>
                  </div>
                </div>
                <div class={styles.row}>
                  <h3>{t('SUCCESS.TOTAL')}</h3>
                  <span>{formatPrice(order.quote.summary.grandTotal)}</span>
                </div>
              </div>

              <div class="row">
                <div class={styles.row}>
                  <h3>{t('SUCCESS.NAME')}</h3>
                  <span>{`${address.firstname} ${address.lastname}`}</span>
                </div>
                <div class={styles.row}>
                  <h3>{t('SUCCESS.ADDRESS')}</h3>
                  <span>{address.street[0]}</span>
                </div>
                <div class={styles.row}>
                  <h3>{t('SUCCESS.POSTCODE')}</h3>
                  <span>{address.postcode}</span>
                </div>
                <div class={styles.row}>
                  <h3>{t('SUCCESS.CITY')}</h3>
                  <span>{address.city}</span>
                </div>
              </div>

              <div class="row">
                <div class={styles.row}>
                  <h3>{t('SUCCESS.EMAIL')}</h3>
                  <span>{order.email}</span>
                </div>
                <div class={styles.row}>
                  <h3>{t('SUCCESS.TELEPHONE')}</h3>
                  <span>{address.telephone}</span>
                </div>
              </div>

              <div class="row">
                <Button
                  onClick={this.handleButtonClick}
                  modifiers="primary">
                  {t('SUCCESS.NEW_ORDER')}
                </Button>
              </div>

            </section>
          </div>

        </Wrapper>
        : null


        }

        <ScrollTop />

        <Helmet
          title={t('SUCCESS.TITLE')} />

      </div>
    );
  }
}

// @flow @jsx h

import type { TProductDetail } from 'types/product';
import { h } from 'preact';
import { PureComponent } from 'shared-components';
import cn from 'classnames';

import ThumbnailList from 'components/ThumbnailList';
import PreloadImage from 'components/PreloadImage';

import styles from './styles.scss';

type Props = {
  product: Object,
};

type State = {
  imageIdx: number | null,
  activeImage: string | null,
};

export default class ProductImages extends PureComponent {
  state: State = {
    imageIdx: null,
    activeImage: null,
  };

  setActiveImage = (idx: number) => {
    this.setState(oldState => ({
      ...oldState,
      activeImage: null,
      imageIdx: idx,
    }));
  }

  getImageList = (state: State, product: Object) => {
    const activeImage = state.activeImage;
    const idx = state.imageIdx;
    const mediaGallery = product.product.mediaGallery;

    if(mediaGallery && idx !== null && mediaGallery[idx]) {
      return [mediaGallery[idx].thumbnail, mediaGallery[idx].large];
    } else if (activeImage && idx === null) {
      return [activeImage];
    } else {
      return [product.product.smallImage, product.product.largeImage];
    }
  }

  componentWillReceiveProps(nextProps: Props) {
    if (this.props.product.activeImage !== nextProps.product.activeImage) {
      this.setState(oldState => ({
        ...oldState,
        imageIdx: null,
        activeImage: nextProps.product.activeImage,
      }));
    }
  }

  render({ product }: Props, state: State) {
    const mediaGallery = product.product.mediaGallery;
    const imageList = this.getImageList(state, product);

    return (
      <div class={cn(styles.productImages, this.props['class'])}>
        <PreloadImage
          class={styles.image}
          imageList={imageList} />

        {mediaGallery && mediaGallery.length > 0 &&
          <ThumbnailList
            class={styles.thumbnailList}
            onClick={this.setActiveImage}
            mediaGallery={mediaGallery}/>
        }
      </div>
    );
  }
}

// @flow @jsx h

import type { Node } from "preact";

import { connect }      from 'preact-redux';
import { h, Component } from "preact";
import styles           from "./styles.scss";
import classNames       from "classnames";
import Helmet           from "preact-helmet";
import SystemMessages   from "containers/SystemMessages";
import Sidebar          from "components/Sidebar";

import { setPageSize } from "store-reducers/category";

type Props = {
  children: Node,
  loggedIn: boolean,
  location: Location
};

@connect(state => ({
  loggedIn: state.user.loggedIn
}), {
  setPageSize
})
export default class App extends Component {
  componentWillMount() {
    this.props.setPageSize(200);
  }

  render({ children, loggedIn, location }: Props, _: any, { t }: Context) {
    return (
      <div class={styles.block}>

        {loggedIn && <Sidebar location={location} />}

        <div class={classNames(styles.wrap)}>
          <main class={styles.main}>
            {children}
          </main>
        </div>
        <SystemMessages />

        <Helmet
          titleTemplate={`%s | ${t("META.TITLE")}`}
          htmlAttributes={{
            dir: "ltr",
            lang: t("LOCALE.ISO.IETF")
          }}
          meta={[
            { charset: "utf-8" },
            { "http-equiv": "x-ua-compatible", content: "ie=edge" },
            { name: "viewport", content: "width=device-width, initial-scale=1" },
            { property: "og:card", content: "summary" },
            { property: "og:site", content: "@resursbankshop" },
            { property: "og:creator", content: "@crossroads" },
            { name: "msapplication-config", content: "/browserconfig.xml" },
            { name: "theme-color", content: "#ffffff" },
            { name: "msapplication-TileColor", content: "#ffffff" },
            { name: "msapplication-TileImage", content: "/ms-icon-144x144.png" },
            { property: "og:locale", content: t("LOCALE.ISO.IETF") },
            { name: "description", content: t("META.DESCRIPTION") },
            { property: "og:site_name", content: t("META.NAME") },
            { property: "og:title", content: t("META.TITLE") }
          ]}
          link={[
            { rel: "apple-touch-icon", sizes: "57x57", href: "/apple-icon-57x57.png" },
            { rel: "apple-touch-icon", sizes: "60x60", href: "/apple-icon-60x60.png" },
            { rel: "apple-touch-icon", sizes: "72x72", href: "/apple-icon-72x72.png" },
            { rel: "apple-touch-icon", sizes: "76x76", href: "/apple-icon-76x76.png" },
            { rel: "apple-touch-icon", sizes: "114x114", href: "/apple-icon-114x114.png" },
            { rel: "apple-touch-icon", sizes: "120x120", href: "/apple-icon-120x120.png" },
            { rel: "apple-touch-icon", sizes: "144x144", href: "/apple-icon-144x144.png" },
            { rel: "apple-touch-icon", sizes: "152x152", href: "/apple-icon-152x152.png" },
            { rel: "apple-touch-icon", sizes: "180x180", href: "/apple-icon-180x180.png" },
            { rel: "icon", type: "image/png", href: "/favicon-16x16.png", sizes: "16x16" },
            { rel: "icon", type: "image/png", href: "/favicon-32x32.png", sizes: "32x32" },
            { rel: "icon", type: "image/png", href: "/favicon-96x96.png", sizes: "96x96" },
            { rel: "manifest", href: "/manifest.json" },
            { rel: "mask-icon", href: "/safari-pinned-tab.svg", color: "#ffffff" },
            { rel: "shortcut icon", href: "/favicon.ico" }
          ]}
          script={[
            { src: "/assets/scripts/modernizr.js" },
          ]}
        />
      </div>
    );
  }
}

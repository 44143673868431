// @flow @jsx h

import { h } from 'preact';
import { PureComponent } from 'shared-components';
import cn from 'classnames';

import CollapseIcon from 'icons/collapse.svg';
import Foldable from 'components/Foldable';

import styles from './styles.scss';

type Props = {
  title: string,
  text?: string,
}

type State = {
  isOpen: boolean,
}

export default class FoldableSection extends PureComponent {
  state: State = {
    isOpen: false
  }

  render({ title, text }: Props, { isOpen }: State) {
    return (
      <div class={this.props['class']}>
        <div class={styles.top} onClick={() => this.setState((state: State) => ({ isOpen: !state.isOpen }))}>
          <span class={styles.title}>{title}</span>
          <div class={cn(
            styles.arrow,
            { [styles['isOpen']]: isOpen },
            )}>
            <CollapseIcon />
          </div>
        </div>
        <hr />
        <div class={styles.bottom}>
          {text &&
            <Foldable
              open={isOpen}
              renderDangerously={true}>
              {text}
            </Foldable>
          }
        </div>
      </div>
    );
  }
}

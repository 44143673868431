// @flow @jsx h

import { h }             from "preact";
import { PureComponent } from "shared-components";
import styles            from "./styles.scss";
import classNames        from "classnames";
import { stutter,
         shortDuration,
         slideRight }    from "helpers/animations";

import Collapse from "icons/collapse.svg";

import Button from "components/Button";

const KEY_ENTER = 13;
const KEY_ESC   = 27;
const KEY_UP    = 38;
const KEY_DOWN  = 40;

const boundKeys = [KEY_ENTER, KEY_ESC, KEY_UP, KEY_DOWN];
const alphanum  = /^[a-z0-9åäö]$/i;

type Props = {
  class?:       string,
  name:         String,
  label:        String,
  options:      Array<any>,
  onSelect:     Function,
  isOpen:       boolean,
  disabled:     boolean,
  value:        string,
  labelKey:     string,
  direction:    string,
  selectOption: Function,
  option:       any,
  active:       boolean,
  style:        string,
};

export default class Dropdown extends PureComponent {
  context:  Context;
  props:    Props;

  static defaultProps = {
    options  : [],
    labelKey : "label"
  }

  constructor(props: Props) {
    super(props);

    this.state = {
      isOpen: !!props.isOpen
    };
  }

  toggleDropdown = () => this.setState({ isOpen: !this.state.isOpen });

  selectOption = (option: any) => {
    this.props.onSelect(option, this.props.name);
    this.toggleDropdown();
  };

  render({ name, disabled, options, value, label, labelKey, direction }: Props, { isOpen }: any, { t }: Context) {
    const selectedOption = options.filter(o => o.id === value)[0] || {};
    const maxLength      = options.reduce((a, o) => Math.max(a, o[labelKey].length), 0);

    return (
      <div
          class={classNames(
            this.props["class"],
            styles.block,
            {[styles.open]: isOpen},
            styles['block__' + direction],
          )}>

        <input
            type="hidden"
            name={name}
            value={value}
            disabled={disabled}
        />

        <button
            type="button"
            onClick={this.toggleDropdown}
            disabled={disabled || options.length === 0}
            class={classNames(
              styles.button,
              "button"
            )}>

          <span
              style={{ minWidth: `${maxLength}ch` }}
              class={styles.label}>

            {selectedOption[labelKey] || label || t("DROPDOWN.TOGGLE")}
          </span>

          <Collapse
              class={classNames(
                styles.icon,
                {[styles.open]: isOpen}
              )}
          />

        </button>

        {(isOpen && options.length > 0) ?
          <DropdownList
              options={options}
              toggleDropdown={this.toggleDropdown}
              selectOption={this.selectOption}
              labelKey={labelKey}
          />
        : null}
      </div>
    );
  }
}

class DropdownList extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      index  : -1,
      search : ""
    };
  }

  handleKeydown = (event: any) => {
    /**
      * don't do anything if a modifier key is pressed. shift is
      * excluded from this list since users may capitalize their
      * text when typing to get to an option.
     **/

    if (event.metaKey || event.ctrlKey || event.altKey) {
      return;
    }

    if (boundKeys.indexOf(event.which) !== -1) {
      event.preventDefault();

      switch (event.which) {
        case KEY_ENTER: return this.props.selectOption(this.props.options[this.state.index]);
        case KEY_ESC:   return this.props.toggleDropdown();
        case KEY_UP:    return this.setIndex(this.state.index - 1);
        case KEY_DOWN:  return this.setIndex(this.state.index + 1);
      }
    }

    if (String.fromCharCode(event.which).match(alphanum)) {
      this.setSearchString(String.fromCharCode(event.which));
    }
  }

  setSearchString = char => {
    this.setState({
      search: this.state.search += char
    }, this.selectOptionBySearch());

    clearTimeout(this.debounce);
    this.debounce = setTimeout(() => this.setState({ search: "" }), 1000);
  }

  selectOptionBySearch = () => {
    const foundOption = this.props.options.filter(option => {
      const label  = option[this.props.labelKey].toLowerCase(),
            search = this.state.search.toLowerCase();

      return label.substring(0, search.length) === search;
    })[0] || this.props.options[0];

    this.setState({
      index: this.props.options.indexOf(foundOption)
    });
  }

  setIndex = index => {
    let value = index;

    if (index >= this.props.options.length) {
      value = 0;
    } else if (value < 0) {
      value = this.props.options.length - 1;
    }

    this.setState({
      index: value
    });
  }

  handleClick = (event: Event) => {
    if (this.base && this.base.contains(event.target)) {
      return;
    }

    event.stopPropagation();
    this.props.toggleDropdown();
  }

  componentDidMount() {
    if (document) {
      document.addEventListener("keydown",  this.handleKeydown, true);
      document.addEventListener("click",    this.handleClick,   true);
      document.addEventListener("touchend", this.handleClick,   true);
    }
  }

  componentWillUnmount() {
    if (document) {
      document.removeEventListener("keydown",  this.handleKeydown, true);
      document.removeEventListener("click",    this.handleClick,   true);
      document.removeEventListener("touchend", this.handleClick,   true);
    }
  }

  render({ options, selectOption, labelKey }: Props, { index }: any, context: Context) {
    return (
      <div class={styles.body}>
        <ul class={styles.list}>
          {options.map((option, i) =>
            <DropdownItem
                option={option}
                selectOption={selectOption}
                active={i === index}
                labelKey={labelKey}
                style={stutter(i / 2)}
            />
          )}
        </ul>
      </div>
    );
  }
}

class DropdownItem extends PureComponent {
  handleClick = () => this.props.selectOption(this.props.option);

  render({ option, active, labelKey, style }: Props, state: any, context: Context) {
    return (
      <li style={style}
          class={styles.item}>

        <button
            type="button"
            onClick={this.handleClick}
            class={classNames(
              "button",
              styles.option,
              {[styles.active]: active}
            )}>

          {option[labelKey]}
        </button>
      </li>
    );
  }
}

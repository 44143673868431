// @flow @jsx h

import { h } from 'preact';
import cn from 'classnames';

import { PureComponent } from 'shared-components';

import styles from './styles.scss';

type Props = {
  url: string,
  id: number,
  alt: string,
  onClick?: Function,
}

export default class Thumbnail extends PureComponent {
  render({ url, id, alt, onClick }: Props, _: any, context: Context ) {
    return (
      <div
        class={cn(
          styles.thumbnail,
          this.props['class'])}
          onClick={(e) => {
            if (typeof onClick === 'function') {
              onClick(e, id)
            }
          }}>

        <img key={url} src={url} alt={alt} />

      </div>
    );
  }
}

// @flow @jsx h

import type { TProduct } from 'types/product';
import type { TCartProduct } from 'types/cart';

import { h } from 'preact';
import { PureComponent } from 'shared-components';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import Thumbnail from 'components/Thumbnail';
import styles from './styles.scss';

type Props = {
  product: TProduct | TCartProduct,
  hintProduct: Function,
  showBorder: boolean,
  wrapTitle: boolean,
  thumbnail?: string,
}

export default class ProductListItem extends PureComponent {
  handleClick = (event: any) => {
    if (!this.props.product.urlKey) {
      event.preventDefault();
      return;
    }

    if (typeof this.props.hintProduct === 'function') {
      this.props.hintProduct(this.props.product);
    }
  }

  render({ product, showBorder = true, wrapTitle = true, thumbnail }: Props, _: any, { t, formatPrice }: Context) {
    return (
      <Link
        to={"/" + product.urlKey}
        onClick={this.handleClick}
        class={cn(
          styles.productListItem,
          { [styles.border]: showBorder },
          this.props['class'])} >

        <div class={styles.thumbnail} >
          <Thumbnail url={thumbnail || product.thumbnail || product.smallImage && product.smallImage} />
        </div>

        <div class={styles.descr}>
          <div class={styles.top}>
            <span class={cn(
              styles.name,
              { [styles.wrap]: wrapTitle })}>
              {product.name}
            </span>
            <span class={styles.price}>{formatPrice(product.price)}</span>
          </div>
          <div class={styles.bottom}>
            <span>{product.attributes.manufacturer}</span>
            <span>{`#${product.sku}`}</span>
          </div>
        </div>

      </Link>
    );
  }
}

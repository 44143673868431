// @flow @jsx h

import { h } from 'preact';
import { connect } from 'preact-redux';
import Helmet from 'preact-helmet';

import { PureComponent } from 'shared-components';
import {
  LOADING  as BRAND_LOADING,
  PENDING as BRAND_PENDING,
  load    as loadBrand,
} from "store-reducers/brand";
import { hintProduct } from "store-reducers/product";

import type { TProduct } from 'types/product';
import ProductList from 'components/ProductList';
import Infobar from 'components/Infobar';
import Wrapper from 'components/Wrapper';
import Loader from 'components/Loader';
import SadFace from 'icons/face_sad.svg';

import styles from './styles.scss';

type Props = {
  brand: any,
  brandLoading: boolean,
  brandPending: boolean,
  products: Array<TProduct>,
  hintProduct: Function,
};

@connect(state => ({
  brand: state.brand.data.brand,
  brandLoading: state.brand.state === BRAND_LOADING,
  brandPending: state.brand.state === BRAND_PENDING,
  products: state.brand.data.products,
}),
  dispatch => ({
    hintProduct: p => dispatch(hintProduct(p)),
    loadBrand: url => dispatch(loadBrand(url)),
  }))
export default class BrandView extends PureComponent {
  componentWillMount() {
    if (this.props.brandPending || this.props.match.params.urlKey !== this.props.brand.urlKey) {
      this.props.loadBrand(this.props.match.params.urlKey);
    }
  }

  render({ brand, products, hintProduct, brandLoading }: Props, _: any, { t }: Context) {
    return (
      <div class={styles.brandView}>
        <Infobar
          title={t('BRANDVIEW.TITLE', { name: brand.name })} />

        <Wrapper>
          {brandLoading &&
            <div class={styles.loading}>
              <Loader dotClass={styles.loadingDot} />
            </div>
          }
          {!brandLoading && !products.length &&
            <div class={styles.empty}>
              <h2>{t('BRANDVIEW.NO_RESULT')}</h2>
              <SadFace />
            </div>
          }
          {!brandLoading && products.length > 0 &&
            <ProductList
              class={styles.productList}
              products={products}
              hintProduct={hintProduct}/>
          }
        </Wrapper>

        <Helmet title={t('BRANDVIEW.TITLE', { name: brand.name })}/>
      </div>
    )
  }
}

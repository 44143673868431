// @flow

import type { TCategory } from "types/category";

export const filterAndSortMainCategories = (categories: Array<TCategory>): Array<TCategory> =>{
  return categories.filter(c => {
   return c.includeInMenu && c.level === 2;
 }).sort((a, b) => {
   return a.name.localeCompare(b.name);
 });
}

// @flow @jsx h

import { h }             from "preact";
import { PureComponent } from "shared-components";
import styles            from "./styles.scss";
import classNames        from "classnames";
import { connect }       from "preact-redux";

import { close } from "store-reducers/messages";

import {
  slideLeftFull,
  fadeOut
} from "helpers/animations";

type Props = {
  messages: Array<{ status: string, id: number, code: string, message: string }>
};

const LIFETIME = 6000;

@connect(state => ({
  messages: state.messages
}), dispatch => ({
  removeMessage: id => dispatch(close(id))
}))
export default class Messages extends PureComponent {

  remove = (id: number) => this.props.removeMessage(id);

  render({ messages }: Props) {
    return (
      <aside
        class={classNames(
          styles.block,
          { [styles.hidden]: messages.length > 0 }
        )}>

        <ul class={styles.list}
          transitionEnter={false}>

          {messages.map(message =>
            <Message
              key={message.id}
              message={message}
              remove={this.remove}
              class={classNames(
                slideLeftFull,
                { [styles.error]: message.status === 'error'})}
            />
          )}
        </ul>
      </aside>
    );
  }
}

class Message extends PureComponent {
  remove = () => this.props.remove(this.props.message.id);

  componentDidMount() {
    setTimeout(this.remove, LIFETIME);
  }

  render({ message }, state, { t }) {
    return (
      <li onClick={this.remove} class={classNames(
        this.props["class"],
        styles.item
      )}>

        <p>
          {message.code ? t(`STATUS_CODE.${message.code}`) : t("STATUS_CODE.UNKNOWN")}
        </p>
      </li>
    );
  }
}

// @flow @jsx h

import { h, Component } from "preact";
import { connect }      from "preact-redux";
import Routes           from "containers/Routes";

import {
  loadAll as loadCategories,
  PENDING as CATEGORIES_PENDING
} from "store-reducers/categories";

import {
  load as loadBrands,
  PENDING as BRANDS_PENDING
} from "store-reducers/brands";


import config           from "../config.js";

export const APP_KEY = "shop";

type Props = {
  location: Location,
  categoriesPending: boolean,
  loadCategories: Function,
  brandsPending: boolean,
  loadBrands: Function
};

@connect(
  state => ({
    location: state.router.location,
    categoriesPending: state.categories.state === CATEGORIES_PENDING,
    brandsPending: state.brands.state === BRANDS_PENDING,
  }),
  dispatch => ({
    loadCmsPages: () => dispatch(loadCmsPages()),
    loadCategories: () => dispatch(loadCategories()),
    loadBrands: () => dispatch(loadBrands()),
  })
)
export class Shared extends Component {
  loadRequiredData = (props: Props) => {};

  loadDeferredData = (props: Props) => {};

  componentWillMount() {
    this.loadRequiredData(this.props);
  }

  componentDidMount() {
    this.loadDeferredData(this.props);
  }

  componentWillReceiveProps(nextProps: Props) {
    this.loadRequiredData(nextProps);
    this.loadDeferredData(nextProps);
  }

  render({ location }: Props) {
    return <Routes location={location} config={config} />;
  }
}

type StateWithUserCountry = {
  user: {
    loggedIn: boolean,
    data: {
      currentCountryCode?: ?string,
      defaultCountry?: ?string
    }
  }
};

export function prefixFetchWithCountry(input: string | Request, stateData: StateWithUserCountry): string | Request {
  const userCode = stateData.user.loggedIn
    ? (stateData.user.data.currentCountryCode || stateData.user.data.defaultCountry)
    : null;
  const countryPrefix = userCode ? `${userCode.toLowerCase()}/` : "";

  if(typeof input === "string") {
    input = `${countryPrefix}api${input}`;
  }
  else {
    input.url = `${countryPrefix}api${input.url}`;
  }

  return input;
}

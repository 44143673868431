// @flow @jsx h

import type { Node } from 'preact';
import { h } from 'preact';
import { PureComponent } from 'shared-components';

import styles from './styles.scss'

type Props = {
  children: Node
}

export default class SidebarNav extends PureComponent {
  render({ children }:Props) {
    return (
      <nav class={styles.sidebarNav}>
        <ul>
          {children}
        </ul>
      </nav>
    )
  }
}

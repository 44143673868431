// @flow

import { routerReducer as router } from "react-router-redux";

import brand                  from "store-reducers/brand";
import brands                 from "store-reducers/brands";
import cart                   from "store-reducers/cart";
import order                  from "store-reducers/order";
import category               from "store-reducers/category";
import categories             from "store-reducers/categories";
import messages               from "store-reducers/messages";
import product                from "store-reducers/product";
import terms                  from "store-reducers/terms";
import view                   from "store-reducers/view";
import checkout               from "store-reducers/checkout";
import modals                 from "store-reducers/modals";
import addresses              from "store-reducers/addresses";

import customer               from "./customer";
import user                   from "./user";
import search                 from "./search";

const noop = x => x;

const reducers = {
  brand,
  brands,
  router,
  cart,
  addresses,
  category,
  checkout,
  lang: noop,
  messages,
  product,
  server: noop,
  terms,
  view,
  modals,
  order,
  customer,
  categories,
  user,
  search
};

export default function reducer(state: Object = {}, action: Object) {
  const newState = {};

  for (let k in reducers) {
    if (reducers.hasOwnProperty(k)) {
      newState[k] = reducers[k](state[k], action);
    }
  }

  return newState;
};

// @flow @jsx h

import { h } from 'preact';
import { PureComponent } from 'shared-components';
import Helmet from 'preact-helmet';
import { connect } from 'preact-redux';

import { set, debounce } from 'diskho';

import {
  Form,
  Field,
  rules,
  required,
  conditional
} from 'formaggio';

import { addressRules, emailRules } from 'helpers/validationRules';

import type { TCart, TCartSummary } from 'types/cart';
import type { TCheckoutData } from 'types/checkout';
import type { TCartItem } from 'types/cart';
import type { TProductDetail } from 'types/product';
import type { TResursCustomer } from 'types/customer';
import Wrapper from 'components/Wrapper';
import Infobar from 'components/Infobar';
import Cart from 'components/Cart';
import ContactFields from 'components/ContactFields';

import {
  setQty,
  hint       as hintCheckout,
  update     as updateData,
  save       as saveData,
  load       as loadCheckout,
  create     as createOrder,
  PENDING    as CHECKOUT_PENDING,
  LOADING    as CHECKOUT_LOADING,
  SUBMITTING as CHECKOUT_SUBMITTING } from "store-reducers/checkout";

import { LOADED as CUSTOMER_LOADED, NOT_FOUND as CUSTOMER_NOT_FOUND } from 'reducers/customer';

import { hintProduct } from "store-reducers/product";

import styles from './styles.scss';

const FORM_NAME = 'checkoutForm';

type Props = {
  customer: TResursCustomer,
  customerLoaded: boolean,
  customerNotFound: boolean,
  cart: TCart,
  checkoutData: TCheckoutData,
  hasError: boolean,
  items: Array<TCartItem>,
  summary: TCartSummary,
  removingItems: Function,
  checkoutLoaded: boolean,
  checkoutLoading: boolean,
  checkoutSubmitting: boolean,
  loadCheckout: Function,
  hintCheckout: Function,
  hintProduct: Function,
  updateData: Function,
  setQty: Function,
  saveData: Function,
  createOrder: Function,
};

const validate = rules([
  ...addressRules(),
  ...emailRules(),
]);

@connect(
  state => ({
    user: state.user.data,
    customer: state.customer.data,
    customerLoaded: state.customer.loaded,
    cart: state.cart.data,
    checkoutData: state.checkout.data,
    hasError: state.checkout.error,
    items: state.checkout.data.cart.items,
    summary: state.checkout.data.cart.summary,
    removingItems: state.checkout.removingItems,
    checkoutLoaded: state.checkout.loaded,
    checkoutLoading: state.checkout.state === CHECKOUT_LOADING,
    checkoutSubmitting: state.checkout.state === CHECKOUT_SUBMITTING,
    checkoutPending: state.checkout.state === CHECKOUT_PENDING,
  }),
  dispatch => ({
    loadCheckout: d => dispatch(loadCheckout(d)),
    hintCheckout: c => dispatch(hintCheckout(c)),
    hintProduct: p => dispatch(hintProduct(p)),
    updateData: d => dispatch(updateData(d)),
    saveData: d => dispatch(saveData(d)),
    setQty: (item: TCartItem, qty: number) => dispatch(setQty(item, qty)),
    createOrder: (d, p) => dispatch(createOrder(d, p)),
  }))
export default class CheckoutPage extends PureComponent {
  constructor(props: Props) {
    super(props);

    this.debounceSaveData = debounce(props.saveData, 100);
  }

  loadCheckout = ({ checkoutData: data, loadCheckout, customer }: Props) => {
    loadCheckout({
      paymentMethod: data.paymentMethod,
      shippingMethod: data.shippingMethod,
      billingAddress    : {
        countryId : customer.countryId,
        firstname: customer.firstname,
        lastname: customer.lastname,
        street: customer.street,
        postcode: customer.postcode,
        city: customer.city,
        telephone: customer.telephone,
        ...data.billingAddress,
        useAsShippingAddress: true,
      },
      shippingAddress: {
        countryId: customer.countryId,
      },
      email: data.email || customer.email,
    })
  }

  hintCheckout = ({ customer }: Props) => {

    const checkoutData = {
      cart: this.props.cart,
      billingAddress: {
        firstname: customer.firstname,
        lastname: customer.lastname,
        street: customer.street,
        postcode: customer.postcode,
        city: customer.city,
        telephone: customer.telephone
      },
      email: customer.email
    }

    this.props.hintCheckout(checkoutData);
  }

  hintProduct = (item: TCartItem) => {
    const product = this.props.cart.items.find(i => i.product.id === item.id);
    this.props.hintProduct(product.product);
  }

  handleSubmit = (event: SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();

    if(this.submitDisabled()) {
      return;
    }

    const orderDetails = {
      ...this.props.checkoutData,
      resursAccountNumber: this.props.customer.accountNr,
    }

    this.props.createOrder(orderDetails);
  }

  handleChange = (data: any) => {
    console.log(data);
    this.props.updateData(data);

    this.debounceSaveData(validate(data).reduce((a, v) => set(a, v.field, null), data));
  }

  handleSubmitError = (event: any, validations: any) => {
    const element = document.querySelector(`[name="${validations[0].field}"]`);

    if (element) {
      element.focus();
    }
  };

  submitDisabled = () =>
    this.props.checkoutSubmitting ||
    this.props.checkoutLoading;

  componentWillMount() {
    if (this.props.customerLoaded) {

      if (!this.props.checkoutLoaded && this.props.cart.items.length > 0) {
        this.hintCheckout(this.props);
      }

      if (this.props.cart.items.length > 0) {
        this.loadCheckout(this.props);
      }
    }
  }

  render({ cart, checkoutData, items, setQty, customer, checkoutSubmitting }: Props, _: any, { t }: Context) {

    const validations = validate(checkoutData);

    return (
      <div class={styles.checkoutPage}>

        <Infobar title={t('INFOBAR.CHECKOUT')}
          logoutDisabled={checkoutSubmitting} />

        <Wrapper>

          {items && items.length > 0 &&
            <Form
              class={styles.container}
              onError={this.handleSubmitError}
              onSubmit={this.handleSubmit}
              onChange={this.handleChange}
              state={checkoutData}
              validations={validations}>

              <div class={styles.left}>
                <h4 class={styles.leftTitle}>{t('CHECKOUTPAGE.ADDRESS_TITLE')}</h4>
                <ContactFields
                  customer={customer}
                  class={styles.address}
                  name={"billingAddress"} />
              </div>

              <Cart
                class={styles.right}
                items={items}
                setQty={setQty}
                hintProduct={this.hintProduct}
                submitting={checkoutSubmitting} />

            </Form>
          }

          {items.length === 0 &&
            <div class={styles.emptyCart}>
              <h1>{t('CHECKOUTPAGE.EMPTY_CART_TITLE')}</h1>
              <p>{t('CHECKOUTPAGE.EMPTY_CART_MESSAGE')}</p>
            </div>
          }

        </Wrapper>

        <Helmet title={t('CHECKOUTPAGE.TITLE')}/>
      </div>
    );
  }
}

// @flow @jsx h

import { h } from 'preact';
import { PureComponent } from 'shared-components';
import Helmet from "preact-helmet";
import { connect } from 'preact-redux';

import { sortBrands } from 'helpers/brands';
import { load as loadBrands, LOADED as BRANDS_LOADED } from 'store-reducers/brands';

import Wrapper from 'components/Wrapper';
import Infobar from 'components/Infobar';
import LinkGrid from 'components/LinkGrid';
import Section from 'components/Section';

import styles from './styles.scss';

type Props = {
  sortedBrands: Array<Object>,
  location: Location,
  brandsLoaded: boolean,
  loadBrands: Function,
};

@connect((state, props) => ({
  sortedBrands: state.brands && state.brands.data && sortBrands(state.brands.data),
  brandsLoaded: state.brands.state === BRANDS_LOADED,
}), dispatch => ({
  loadBrands: () => dispatch(loadBrands()),
}))
export default class BrandsPage extends PureComponent {
  componentDidMount() {
    if(this.props.location.hash) {
      this.scroll(this.props.location.hash);
    }
  }

  componentWillMount() {
    if(!this.props.brandsLoaded) {
      this.props.loadBrands();
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.location.hash !== prevProps.location.hash) {
      this.scroll(this.props.location.hash);
    }
  }

  scroll = (anchor: string) => {
    if (anchor) {
      const element = this.sectionContainer.querySelector(anchor);

      if(element) {
        this.brandsPage.scrollTop = element.offsetTop;
      }
    } else {
      this.brandsPage.scrollTop = 0;
    }
  }

  render({ sortedBrands }: Props, _: any, { t }: Context) {
    const links = sortedBrands.map(b => {
      return { name: b.id, link: `#${b.id}`};
    });

    return (
      <div class={styles.brandsPage} ref={node => this.brandsPage = node}>

        <Infobar
          title={t('INFOBAR.BRANDS')} />

        <Wrapper>
          <div class={styles.container}>

            <div class={styles.left}>
              {links &&
                <LinkGrid links={links} linkClass={styles.link} />
              }
            </div>

            <div class={styles.right} ref={node => this.sectionContainer = node}>
              {sortedBrands && sortedBrands.length > 0 &&
                sortedBrands.map(brandSection => {
                   return (
                    <Section
                      head={brandSection.id}
                      id={brandSection.id}
                      childrens={brandSection.data}
                      linkClass={styles.sectionLink}
                      linksPerRow={4}
                      urlPrefix="brand"
                      linkAttribute="urlKey" />
                  )
                })
              }
            </div>
          </div>
        </Wrapper>

        <Helmet title={t('BRANDSPAGE.TITLE')}/>
      </div>
    );
  }
}



// @flow @jsx h

import { h } from "preact";
import { PureComponent } from "shared-components";
import styles from "./styles.scss";
import cn from "classnames";

type Props = {
  text: string,
  checked: boolean,
  name: string,
  value: string,
  buttonClass?: string
}

export default class Radio extends PureComponent {
  handleChange = (event: SyntheticEvent<HTMLInputElement>) => {
    event.preventDefault();
    if (typeof this.props.onChange === "function") {
      this.props.onChange(event.currentTarget.value);
    }
  }

  handleClick = () => {
    this.radio.click();
  }

  render({ text, checked, name, value, buttonClass }: Props, _: any, { t }: Context) {
    return (
      <div
        class={cn(
          this.props["class"],
          styles.block
        )}>

        <input
          type="radio"
          ref={input => this.radio = input}
          checked={checked}
          onChange={this.handleChange}
          name={name}
          value={value}
          class={styles.input}
          tabindex="-1"
        />

        <button
          type="button"
          class={cn(
                styles.button,
                checked ? styles.checked : ''
            )}
          onClick={this.handleClick}>
          {text}
        </button>
      </div>
    );
  }
}

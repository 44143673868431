module.exports = {
  host: "localhost",
  port: 9118,
  apiHost: {
    server       : "http://127.0.0.1/",
    client       : "/",
    proxyHeaders : ["SSL", "Ssl-Offload", "X-Forwarded-Proto", "Host", "X-Real-Ip", "X-Forwarded-For"],
  },
  cspUri: null,
  googleAnalytics: {
    en_GB: {
      currencyCode: "SEK",
      accounts: ["UA-128549695-3"]
    },
  },
  isProduction: true
};

// @flow @jsx h

import type { TResursCustomer } from 'types/customer';
import { h } from 'preact';
import { PureComponent } from 'shared-components';
import cn from 'classnames';
import { connect } from 'preact-redux';

import Button from 'components/Button';
import StartIcon from 'icons/start.svg';
import QuestionIcon from 'icons/question.svg';
import Loader from 'components/Loader';
import Tooltip from 'components/Tooltip';

import {
  unload as unloadCustomer,
  UNLOADING } from 'reducers/customer';

import { LOADING } from 'reducers/search';

import styles from './styles.scss';

type Props = {
  customer: TResursCustomer,
  unloadCustomer: Function,
  processing: boolean,
  searching: boolean,
}

@connect(state => ({
  customer: state.customer.data,
  processing: state.customer.state === UNLOADING,
  searching: state.search.state === LOADING,
}), dispatch => ({
  unloadCustomer: () => dispatch(unloadCustomer()),
}))
export default class LogoutWidget extends PureComponent {
  render({ unloadCustomer, customer, processing, searching }: Props, _: any, { t }: Context ) {
    return (
      <div class={cn(
        styles.logoutWidget,
        this.props['class'] && this.props['class'],
        { [this.props['processClass']]: this.props['processClass'] && processing })}>

        <div class={cn(
          styles.primary,
          { [styles.hidden]: processing })}>

          <div class="row--small">
            <div class={styles.top}>
              <div class={styles.left}>
                <StartIcon class={styles.icon}/>
                <span>{t('WIDGET.SIGNED_IN_AS').toUpperCase()}</span>
              </div>
              <Tooltip
                text={t('WIDGET.TOOLTIP_SIGN_OUT_CUSTOMER')}>
                <QuestionIcon />
              </Tooltip>
            </div>
          </div>

          <div class="row--small">
            <div class={styles.bottom}>
              <div class={styles.info}>
                <p>{customer.accountNr}</p>
                <p>{t(`COUNTRY.${customer.countryId}`)}</p>
              </div>
              <Button
                modifiers="danger"
                disable={searching}
                type="submit"
                icon="logout"
                onClick={!searching && unloadCustomer}
              />
            </div>
          </div>

        </div>

        {processing &&
          <div class={styles.loader}>
            <Loader
              dotClass={styles.loadingDot}/>
          </div>
        }
      </div>
    );
  }
}

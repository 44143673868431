// @flow @jsx h

import { h } from 'preact';
import { PureComponent } from 'shared-components';
import cn from 'classnames';
import Helmet from 'preact-helmet';
import { connect } from 'preact-redux';
import { push } from "react-router-redux";

import Wrapper from 'components/Wrapper';
import Infobar from 'components/Infobar';
import SearchWidget from 'components/SearchWidget';
import SearchResult from 'components/SearchResult';

import {
  search as searchProduct,
  clear as clearSearch,
  PENDING as SEARCH_PENDING,
  LOADED as SEARCH_LOADED } from 'reducers/search';

import styles from './styles.scss';

type Props = {
  clearSearch: Function,
  pushRoute: Function,
  searched: boolean,
  searchPending: boolean,
  match: any,
};

@connect(state => ({
  searchPending: state.search.state === SEARCH_PENDING,
  searched: state.search.state === SEARCH_LOADED,
}), dispatch => ({
  clearSearch: () => dispatch(clearSearch()),
  pushRoute: (query) => dispatch(push(`/search/${query}`)),
  searchProduct: (query) => dispatch(searchProduct(query)),
}))
export default class SearchPage extends PureComponent {
  componentWillMount() {
    if (this.props.match.params.query && this.props.searchPending) {
      this.props.searchProduct(this.props.match.params.query);
    }
  }

  componentWillRecieveProps(nextProps: Props) {
    if (nextProps.match.params.query !== this.props.match.params.query) {

      if (nextProps.match.params.query){
        this.props.searchProduct(nextProps.match.params.query);
      } else {
        this.props.clearSearch();
      }
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.match.params.query !== this.props.match.params.query) {
      if (this.props.match.params.query) {
        this.props.searchProduct(this.props.match.params.query);
      } else {
        this.props.clearSearch();
      }
    }
  }

  componentWillUnmount() {
    this.props.clearSearch();
  }

  render({ searched, pushRoute }: Props, _2: any, { t }: Context) {
    return (
      <div class={styles.block}>
        <Infobar
          title={t('INFOBAR.SEARCH')} />

          <div class={styles.topRow}>
            <Wrapper>
              <div class={styles.container}>
                <SearchWidget
                  class={styles.searchWidget}
                  key="searchPage"
                  onSubmit={pushRoute}/>
              </div>
            </Wrapper>
          </div>

        {searched &&
          <div class="row">
            <Wrapper modifiers="flex column">
              <SearchResult />
            </Wrapper>
          </div>
        }

        <Helmet title={t('SEARCHPAGE.TITLE')}/>
      </div>
    );
  }
}

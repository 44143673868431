// @flow @jsx h

import type { TResursCustomer } from 'types/customer'
import { h } from 'preact';
import { PureComponent } from 'shared-components';
import { connect } from 'preact-redux';
import cn from 'classnames';

import { unload as unloadCustomer, UNLOADING, LOADING, LOADED, NOT_FOUND } from 'reducers/customer';

import { LOADING as SEARCHING } from 'reducers/search';

import Wrapper from 'components/Wrapper';
import Button from 'components/Button';
import SuccessIcon from 'icons/success.svg';

import styles from './styles.scss';

type Props = {
  title: string,
  customerLoaded: boolean,
  customerLoading: boolean,
  customerUnloading: boolean,
  showUnloadSuccess: boolean,
  unloadCustomer: Function,
  customer?: TResursCustomer,
  searching: boolean,
  titleClass?: string,
  logoutDisabled: boolean,
};

@connect(state => ({
  customerLoaded: state.customer.state === LOADED || state.customer.state === NOT_FOUND,
  customerLoading: state.customer.state === LOADING,
  customerUnloading: state.customer.state === UNLOADING,
  showUnloadSuccess: state.customer.showUnloadSuccess,
  customer: state.customer.data,
  searching: state.search.state === SEARCHING,
}), dispatch => ({
  unloadCustomer: () => dispatch(unloadCustomer()),
}))
export default class Infobar extends PureComponent {
  render({ title, customer, customerLoaded, customerLoading, customerUnloading, showUnloadSuccess, unloadCustomer, searching, titleClass, logoutDisabled = false }: Props, _: any, { t }: Context) {
    return (
      <div class={styles.block}>
        <Wrapper class={styles.wrapper}>

          <div class={styles.left}>
            <h3 class={cn(styles.title, titleClass)}>
              {title}
            </h3>
          </div>

          <div class={styles.right}>
            {customerUnloading &&
              <span class={styles.logoutMessage}>{t('INFOBAR.SIGN_OUT_MESSAGE')}</span>
            }

            {customerLoading &&
              <span class={styles.loginMessage}>{t('INFOBAR.SIGN_IN_MESSAGE')}</span>
            }

            {showUnloadSuccess &&
              <div class={styles.loggedOutMessage}>
                <SuccessIcon />
                <span>{t('INFOBAR.SIGNED_OUT_SUCCESS')}</span>
              </div>
            }

            {!customerLoading && customerLoaded && customer &&
              <div class={styles.customerActions}>
                <div class={styles.customerNumber}>
                  <span>{t('INFOBAR.CUSTOMER_NUMBER').toUpperCase()}</span>
                  <span>{customer.accountNr}</span>
                </div>
                <Button
                  modifiers="danger tiny"
                  disable={logoutDisabled || searching}
                  icon="logout"
                  iconClass={styles.buttonIcon}
                  iconPosition="before"
                  onClick={!logoutDisabled && !searching && unloadCustomer}>
                  {t('INFOBAR.SIGN_OUT_CUSTOMER')}
                </Button>
              </div>
            }
          </div>
        </Wrapper>
      </div>
    );
  }
}

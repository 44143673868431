// @flow @jsx h

import type { Node }        from "preact";
import { h }                from "preact";
import { PureComponent }    from "shared-components";
import cn                   from "classnames";
import { Link, matchPath }  from "react-router-dom";
import Loader               from 'components/Loader';
import { connect }          from "preact-redux";

import BrandIcon            from "icons/brand.svg";
import CategoryIcon         from "icons/category.svg";
import CheckoutIcon         from "icons/checkout.svg";
import SearchIcon           from "icons/search.svg";
import StartIcon            from "icons/start.svg";
import ArrowIcon            from 'icons/arrow.svg';
import LogoutIcon           from 'icons/logout.svg';
import TrashbinIcon         from 'icons/trashbin.svg';
import CopyIcon             from 'icons/copy.svg';

import styles               from "./styles.scss";

const ICONS = {
  brand: BrandIcon,
  category: CategoryIcon,
  checkout: CheckoutIcon,
  search: SearchIcon,
  start: StartIcon,
  arrow: ArrowIcon,
  logout: LogoutIcon,
  trashbin: TrashbinIcon,
  copy: CopyIcon,
}

type Props = {
  elem?: Element,
  modifiers: string,
  type?: string,
  children: Array<Node>,
  icon: string,
  iconPosition: string,
  iconRotation: string,
  iconClass?: string,
  processing: boolean,
  activeClassname?: string,
  disable: boolean,
  buttonWrapClass: string,
  active: boolean,
};

export default class Button extends PureComponent {
  static defaultProps = {
    modifiers: "",
    iconPosition: "after",
    processing: false,
  }

  renderIcon = (IconComponent: Node, position: "before" | "after") => {
    const style = {};

    if (this.props.iconRotation) {
      style["transform"] = `rotate(${this.props.iconRotation}deg)`;
    }

    return <IconComponent class={cn("button__icon", `button__icon--${position}`, this.props.iconClass)} style={style} />;
  }

  render({ children, elem, processing, modifiers, type, icon, iconPosition, iconRotation, activeClassname, disable, buttonWrapClass, active = false, ...props }: Props, _: any, { t }: Context) {
    const Elem = elem || (this.props.to ? Link : "button");
    const IconComponent = icon && ICONS[icon];

    const hasText = children.length > 0 && typeof children[0] === "string";

    return (
      <Elem
        {...props}
        type={type || (this.props.to || this.props.href ? false : "button")}
        class={cn(
          "button",
          this.props["class"],
          { ["disabled"]: disable },
          { ["button__hasText"]: hasText },
          ...modifiers.split(" "),
          activeClassname && {[activeClassname]: active},
        )}>

        {processing
          ? <Loader/>
          : <div class={cn("button__wrap", buttonWrapClass)}>

            {IconComponent && iconPosition === "before" && this.renderIcon(IconComponent, "before")}

            {children}

            {IconComponent && iconPosition === "after" && this.renderIcon(IconComponent, "after")}

        </div>}
      </Elem>
    );
  }
}

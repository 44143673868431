// @flow @jsx h

import { h } from 'preact';
import { PureComponent, guardModifiers } from 'shared-components';
import { connect } from 'preact-redux';
import cn from 'classnames';
import {
  Form,
  Field } from "formaggio";

import SearchIcon from 'icons/search.svg';
import QuestionIcon from 'icons/question.svg';
import InputButtonField from 'components/InputButtonField';
import Button from 'components/Button';
import Loader from 'components/Loader';
import Tooltip from 'components/Tooltip';

import { LOADING as SEARCHING_PRODUCT } from 'reducers/search';

import styles from './styles.scss';

type Props = {
  fieldLabel?: string,
  modifiers: string,
  processing: boolean,
  processClass: string,
  onSubmit: Function,
  customKey?: string
}

type State = {
  query: string,
}

@connect(state => ({
  processing: state.search.state === SEARCHING_PRODUCT,
}), {})
export default class SearchWidget extends PureComponent {
  state: State = {
    query: "",
  }

  static defaultProps = {
    modifiers: ""
  }

  searchProduct = (e: SyntheticEvent<HTMLButtonElement>, state: State) => {
    e.preventDefault();

    if(!state.query) {
      return;
    }

    this.props.onSubmit(state.query);

    this.setState({
      query: ""
    });
  }

  render({ modifiers, processing, fieldLabel }: Props, state: State, { t }: Context) {
    return (
      <div
        class={cn(
        styles.searchWidget,
        this.props['class'] && this.props['class'],
        { [this.props['processClass']]: this.props['processClass'] && processing } ,
        ...modifiers.split(" ").map(m => styles[m]))}>

        <div class={styles.top}>
          <div class={styles.left}>
            <SearchIcon class={styles.icon}/>
            <span>{t('WIDGET.SEARCH_PRODUCT').toUpperCase()}</span>
          </div>
          <Tooltip
            text={t('WIDGET.TOOLTIP_SEARCH')}>
            <QuestionIcon />
          </Tooltip>
        </div>

        <div class={styles.bottom}>
          <Form
            class={cn(
              styles.form,
              { [styles.hidden]: processing })}
            onSubmit={this.searchProduct}
            onChange={x => this.setState(x)}
            onError={e => console.log(e)}
            state={this.state}>

              <Field
                name="query"
                label={fieldLabel}
                buttonIcon="search"
                component={InputButtonField}
                />

          </Form>

          {processing &&
            <div class={styles.loader}>
              <Loader
                dotClass={styles.loadingDot}/>
            </div>
          }
        </div>
      </div>
    );
  }
}

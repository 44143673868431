// @flow @jsx h

import type { TProduct } from 'types/product'
import { h } from 'preact';
import { PureComponent } from 'shared-components';
import cn from 'classnames';

import ProductListItem from 'components/ProductListItem'

import styles from './styles.scss';

type Props = {
  products: Array<TProduct>,
  hintProduct: Function,
}

export default class ProductList extends PureComponent {
  render({ products, hintProduct }: Props) {
    return (
      <div class={cn(styles.block, this.props['class'])}>
        {products.map(p => (
          <div class={styles.product}>
            <ProductListItem product={p} hintProduct={hintProduct} />
          </div>))
        }
      </div>
    );
  }
}

// @flow

import {
  conditional,
  isEmail,
  isNumeric,
  nestedRule,
  required,
  isPhone } from "formaggio";

const CUSTOMER_ID_LENGTH = 11;

export const lengthEq = (fieldName: string, length: number): Validator<*> => state =>
  state[fieldName] && state[fieldName].length < length ?
    [{ error: "LENGTH_GT", field: fieldName}] :
      state[fieldName].length > length ?
        [{ error: "LENGTH_LT", field: fieldName}] :
        [];


export function emailRules(key: string = "email") {
  return [
    required(key),
    conditional(o => o[key], isEmail(key)),
  ]
}

export function addressRules(key: string = "billingAddress") {
  return [
    nestedRule(key, required("firstname")),
    nestedRule(key, required("lastname")),
    nestedRule(key, nestedRule("street", required("0"))),
    nestedRule(key, required("postcode")),
    nestedRule(key, required("city")),
    nestedRule(key, required("telephone")),
    nestedRule(key, conditional(o => o.telephone, isPhone("telephone"))),
  ];
}

export function loginCustomerRules(key: string = "customerid") {
  return [
    required(key),
    conditional(o => o[key], isNumeric(key)),
    conditional(o => o[key], lengthEq(key, CUSTOMER_ID_LENGTH)),
  ]
}

export function validateLogin(key: string, value: string) {
  let errors = [];

  switch(key) {
    case 'epost':

      if(!value.length) {
        errors.push({ field: key, error: "REQUIRED" });
      }

      if(!/\S+@\S+\.\S+/.test(value)) {
        errors.push({ field: key, error: "EMAIL" });
      }

      break;

    case 'password':
      if(!value.length) {
        errors.push({ field: key, error: "REQUIRED" });
      }
  }

  return errors;
}

// @flow @jsx h

import { h } from 'preact';
import { PureComponent } from 'shared-components';
import styles from './styles.scss';
import cn from 'classnames';

type Props = {
  children?: Node,
  modifiers: string,
  element?: Element
}

export default class Wrapper extends PureComponent {
  static defaultProps = {
    element: "div",
    modifiers: ""
  }

  render({ children, modifiers, element: Elem }: Props) {
    return (
      <Elem
        class={cn(
          this.props['class'],
          styles.wrapper,
          ...modifiers.split(" ").map(m => styles[m])
        )}>
        {children}
      </Elem>
    );
  }
}

// @flow @jsx h

import { h }      from "preact";
import styles     from "./styles.scss";
import classNames from "classnames";
import Helmet     from "preact-helmet";

import {
  PureComponent,
  ScrollTop
} from "shared-components";

import {
  stutter,
  slideUp
} from "helpers/animations";

export default class ServerError extends PureComponent {
  render() {
    const t = this.context.t;
    return (
      <div class={styles.block}>
        <div class={styles.body}>
          <div>
            <h1 class={slideUp}>
              <span
                style={{ display: "block" }}
                class={slideUp}>

                {t("SERVER_ERROR.HEADING")}
              </span>

              <span
                style={stutter(1)}
                class={classNames(
                  "subheading",
                  "text--small",
                  slideUp
                )}>

                {t("SERVER_ERROR.SUBHEADING")}
              </span>
            </h1>
          </div>
        </div>

        <ScrollTop />

        <Helmet title={t("SERVER_ERROR.TITLE")} />
      </div>
    );
  }
}

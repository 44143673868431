// @flow @jsx h

import { h, Component } from "preact";
import Translation      from "helpers/translation";
import formatPrice      from "helpers/formatPrice";

export default class WithContext extends Component {
  getChildContext = () => {
    const t = new Translation(this.props.translation);

    return {
      t: t.translate.bind(t),
      formatPrice: formatPrice({
        id: t.translate("LOCALE.PRICE_FORMAT"),
        currency: t.translate("LOCALE.CURRENCY"),
        symbol: t.translate("LOCALE.FALLBACK_CURRENCY")
      }),
    };
  }

  render() {
    return this.props.children[0];
  }
}

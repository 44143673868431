// @flow

import { interpolate } from "diskho";

export default class Translation {
  translations;

  constructor(data) {
    this.translations = data;
  }

  translateFallback(key, reason) {
    if (process.env.NODE_ENV !== "production") {
      console.error(`Translate: "${key}" key is ${reason}`);
      return `[${key}]`;
    }

    return "";
  }

  translate(key, args = {}) {
    const path = key.split(".");
    let data = this.translations;

    for (let i = 0; i < path.length; i++) {
      if (typeof data !== "object" || !(path[i] in data)) {
        return this.translateFallback(key, "missing");
      }

      data = data[path[i]];
    }

    if (!data || typeof data !== "string") {
      if (process.env.NODE_ENV !== "production") {
        console.warn(`Translate: "${key}" is not a string.`);

        return `[${key}]`;
      }

      return "";
    }

    return interpolate(data, args, key);
  }

  get(key) {
    return this.translations[key];
  }
}

// @flow @jsx h

import { h } from 'preact';
import { PureComponent } from 'shared-components';

import Button from 'components/Button';
import PlusIcon from 'icons/plus.svg';
import MinusIcon from 'icons/minus.svg'

import styles from './styles.scss';

type Props = {
  value: number,
  increment: Function,
  decrement: Function,
};

const INPUT_PADDING = "2.4em"; // 2 times the padding declared for input_padding in _variables.scss

export default class QtyPicker extends PureComponent {
  render({ value, increment, decrement }: Props) {
    return (
      <div class={styles.qtyPicker}>
        <Button
          onClick={decrement}
          modifiers="primary tiny"
          class={styles.button_left}>
          <MinusIcon />
        </Button>

        <input
          disabled={true}
          style={{ width: `calc(${value.toString().length}ch + ${INPUT_PADDING})`}}
          type="text"
          value={value}
          class={styles.field} />

        <Button
          onClick={increment}
          modifiers="primary tiny"
          class={styles.button_right}>
          <PlusIcon />
        </Button>
      </div>
    )
  }
}

// @flow @jsx h

/**
 * Component that loads image based on order added in the imageList array.
 * The higher index of the string in the array, the better the quality (if right link is added)
 */

import { h } from 'preact';
import { PureComponent } from 'shared-components';

type Props = {
  imageList: Array<string>,
}

type State = {
  currentId: number
}

export default class PreloadImage extends PureComponent {
  element: any = null;

  state: State = {
    currentId: -1
  }

  setReference = (el: any) => this.element = el;

  empty = (urls: Array<string>) => {
    return !(urls.filter(u => typeof u === 'string').length > 0);
  }

  loadImages(urls: Array<string>) {
    this.setState({currentId: -1}, () => {
      if(!this.empty(urls)) {
        urls.forEach((src, idx) => {
          if (src) {
            new Promise((resolve, reject) => {
              const img = new Image();
              img.src = src;

              if (img.complete) {
                resolve({ id: idx, image: img });
              } else {
                img.onload = () => resolve({ id: idx, image: img });
              }

              img.onerror = () => reject();

            }).then(img => {
              if (parseInt(img.id, 10) > this.state.currentId) {
                this.element.src = img.image.src;
                this.setState({ currentId: img.id });
              }
            })
          }
        });
      } else {
        this.element.src = '';
      }
    });

  }

  componentWillMount() {
    this.loadImages(this.props.imageList);
  }

  componentWillReceiveProps(nextProps: Props, nextState: State) {
    this.loadImages(nextProps.imageList);
  }

  render({ imageList, ...rest }: Props, state: State) {
    return (
      <img {...rest} ref={this.setReference}/>
    )
  }
}

// @flow @jsx h

import { h } from 'preact';
import { PureComponent } from 'shared-components';
import ResursLogo from "icons/resursbank.svg";

import styles from './styles.scss';

export default class SidebarHeader extends PureComponent {
  render() {
    return (
      <div class={styles.sidebarHeader}>
        <ResursLogo />
      </div>
    )
  }
}

// @flow @jsx h

import { h } from 'preact';
import { PureComponent } from 'shared-components';
import { connect } from 'preact-redux';
import cn from 'classnames';

import SidebarHeader from 'components/SidebarHeader';
import SidebarNav from 'components/SidebarNav';
import SidebarNavItem from 'components/SidebarNavItem';
import SidebarFooter from 'components/SidebarFooter';

import { getRootSegment } from 'helpers/location';
import { fadeIn } from "helpers/animations";

import { logout } from 'reducers/user';
import { LOADED as CUSTOMER_LOADED, NOT_FOUND as CUSTOMER_NOT_FOUND } from 'reducers/customer';

import styles from './styles.scss'

type Props = {
  user: Object,
  customerLoaded: boolean,
  customerCountry?: string,
  logout: Function,
  itemsInCart: number,
  pathname: string,
};

@connect(state => ({
    itemsInCart: state.cart.data.items.length,
    pathname: state.router.location ? state.router.location.pathname : state.server.pathname,
    user: state.user.data,
    customerLoaded: state.customer.state === CUSTOMER_LOADED || state.customer.state === CUSTOMER_NOT_FOUND,
    customerCountry: state.customer.data.countryId
}), dispatch => ({
  logout: () => dispatch(logout())
}))
export default class Sidebar extends PureComponent {
  render({ logout, customerLoaded, customerCountry, user, pathname, itemsInCart }: Props, _:any, { t }: Context) {
    const rootSegment = getRootSegment(pathname);

    return (
      <aside class={styles.sidebar}>
        <div>
          <SidebarHeader />

          <SidebarNav>
            <SidebarNavItem
              to="/start"
              active={rootSegment === "start"}
              icon="start"
              iconPosition="before">
              {t('SIDEBAR.START')}
            </SidebarNavItem>
            <SidebarNavItem
              to="/search"
              active={rootSegment === "search"}
              icon="search"
              iconPosition="before">
              {t('SIDEBAR.SEARCH')}
            </SidebarNavItem>
            <SidebarNavItem
              to="/categories"
              active={rootSegment === "categories"}
              icon="category"
              iconPosition="before">
              {t('SIDEBAR.CATEGORIES')}
            </SidebarNavItem>
            <SidebarNavItem
              to="/brands"
              active={rootSegment === "brands"}
              icon="brand"
              iconPosition="before">
              {t('SIDEBAR.BRANDS')}
            </SidebarNavItem>
            <SidebarNavItem
              to="/checkout"
              active={rootSegment === "checkout"}
              icon="checkout"
              iconPosition="before"
              buttonWrapClass={styles.sidebarButton}
              >
              <div class={styles.checkout}>
                <span>{t('SIDEBAR.CHECKOUT')}</span>
                {itemsInCart > 0 &&
                  <div class={cn(styles.cartStatus, fadeIn)}>{itemsInCart}</div>
                }
              </div>
            </SidebarNavItem>
          </SidebarNav>
        </div>

        <SidebarFooter
          name={user.name}
          customerLoggedIn={customerLoaded}
          country={customerLoaded ? customerCountry : user.defaultCountry}
          onLogout={logout} />
      </aside>
    )
  }
}

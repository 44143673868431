// @flow @jsx h

import { h } from 'preact';
import { PureComponent } from 'shared-components';
import cn from 'classnames';

import { Field } from 'formaggio';
import type { TResursCustomer } from 'types/customer';
import InputField from 'components/InputField';

import { mapShortToCountry } from 'helpers/locales';

import styles from './styles.scss';

type Props = {
  name: string,
  customer: TResursCustomer,
}

export default class ContactFields extends PureComponent {
  shouldComponentUpdate() {
    return true;
  }

  render({ name, customer }: Props, _: any, { t }: Context) {
    const country: { text: string, value: string } | boolean = mapShortToCountry(customer.countryId, t);

    return (
      <div class={cn(
        styles.contactFields,
        this.props['class'])}>

        <div class={cn(styles.row, styles.double)}>
          <div class={styles.prefilled}>
            <span class={styles.label}>{t('CHECKOUTPAGE.CUSTOMER_NUMBER')}</span>
            <span>{customer.accountNr}</span>
          </div>
          <div class={styles.prefilled}>
            <span class={styles.label}>{t('CHECKOUTPAGE.COUNTRY')}</span>
            <span>{typeof country !== 'boolean' ? country.text : ''}</span>
          </div>
        </div>

        <div class={cn(styles.row, styles.double)}>
          <Field
            name={`${name}.firstname`}
            label={t('CHECKOUTPAGE.FIRST_NAME')}
            labelClass={cn(styles.label, styles.required)}
            component={InputField} />

          <Field
            name={`${name}.lastname`}
            label={t('CHECKOUTPAGE.LAST_NAME')}
            labelClass={cn(styles.label, styles.required)}
            component={InputField} />
        </div>

        <div class={styles.row}>
          <Field
            name={`${name}.street.0`}
            label={t('CHECKOUTPAGE.ADDRESS')}
            labelClass={cn(styles.label, styles.required)}
            component={InputField} />
        </div>

        <div class={styles.row}>
          <Field
            name={`${name}.street.1`}
            label={t('CHECKOUTPAGE.ADDRESS2')}
            labelClass={styles.label}
            component={InputField} />
        </div>

        <div class={cn(styles.row, styles.double)}>
          <Field
            name={`${name}.postcode`}
            label={t('CHECKOUTPAGE.POSTCODE')}
            labelClass={cn(styles.label, styles.required)}
            component={InputField} />

          <Field
            name={`${name}.city`}
            label={t('CHECKOUTPAGE.CITY')}
            labelClass={cn(styles.label, styles.required)}
            component={InputField}
            value="hello" />
        </div>

        <div class={styles.row}>
          <Field
            name={`${name}.telephone`}
            label={t('CHECKOUTPAGE.TELEPHONE')}
            labelClass={cn(styles.label, styles.required)}
            component={InputField} />
        </div>

        <div class={styles.row}>
          <Field
            name="email"
            label={t('CHECKOUTPAGE.EMAIL')}
            labelClass={cn(styles.label, styles.required)}
            component={InputField} />
        </div>

      </div>
    );
  }
}

// @flow @jsx h

import { h } from 'preact';
import { PureComponent } from 'shared-components';

import type { TProductDetail } from 'types/product';
import FoldableSection from 'components/FoldableSection';
import ProductStatus from 'components/ProductStatus';
import Button from 'components/Button';
import ProductOption from 'components/ProductOption';
import { Form } from "formaggio";

import styles from './styles.scss';

type Props = {
  product: any,
  formData: Object,
  setOption: Function,
  options: Array<any>,
  buttonEnabled: boolean,
  addToCart: Function,
  setFormData: Function,
  options: Array<any>,
  name: string,
  brand: string,
  customerLoaded: boolean,
  processing: boolean,
};


export default class ProductInfo extends PureComponent {

  addToCart = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();
    this.props.addToCart();
  }

  render({ product, formData, setOption, setFormData, options, buttonEnabled, brand, name, customerLoaded, processing }: Props, _: any, { t }: Context) {
    return (
      <div>
        <div class={styles.top}>
          <ProductStatus
            brand={brand}
            name={name}
            product={product}
            />
        </div>
        {product.isSalable && customerLoaded &&
          <div class={styles.middle}>
            <Form
              class={styles.actions}
              state={formData}
              onChange={setFormData}
              onSubmit={this.addToCart}
              >
              {options &&
                <div class={styles.options}>
                  {options && options.map(option =>
                    <ProductOption
                      option={option}
                      class={styles.option}
                      setOption={setOption}
                      selectedValue={Object.keys(formData.attributes).length > 0 ? formData.attributes[option.id] : null} />)
                  }
                </div>
              }
              <Button
                processing={processing}
                disabled={!buttonEnabled || !product.isInStock}
                modifiers="primary"
                class={styles.button}
                type="submit">
                {t('PRODUCT.ADD_TO_CART')}
              </Button>
            </Form>
          </div>
        }
        <div class={styles.bottom}>
          <FoldableSection title={t('PRODUCT.DESCRIPTION')} text={product.description}/>
        </div>
      </div>
    );
  }
}

import {
  stutter as stutterDuration
} from "./keyframes.scss";

export {
  fadeIn,
  fadeOut,
  paused,
  short as shortDuration,
  slow  as slowDuration,
  slideDown,
  slideDownOut,
  slideLeft,
  slideLeftFull,
  slideRight,
  slideRightOutFull,
  slideRightOut,
  slideUp,
  slideUpFull
} from "./keyframes.scss";

export function stutter(timing, limit = 100) {
  if (timing === 0) {
    return {};
  }

  return {
    animationDelay: `${stutterDuration * Math.min(timing, limit)}ms`
  };
}

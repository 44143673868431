// @flow @jsx h

import { h } from 'preact';
import { connect } from 'preact-redux';
import Helmet from 'preact-helmet';

import type { TProduct } from 'types/product';
import type { TCategory } from 'types/category';
import { PureComponent } from 'shared-components';
import { HINTED } from "store-reducers/category";
import { LOADING } from "store-reducers/view";
import { hintProduct } from "store-reducers/product";

import SadFace from 'icons/face_sad.svg';
import Infobar from 'components/Infobar';
import Wrapper from 'components/Wrapper';
import ProductList from 'components/ProductList';
import Loader from 'components/Loader';

import styles from './styles.scss';

type Props = {
  category: TCategory,
  products: Array<TProduct>,
  hinted: boolean,
  hintProduct: Function,
  loading: boolean,
};

@connect(
  state => ({
    category: state.category.category,
    products: state.category.pages[0] ? state.category.pages[0].items : [],
    hinted: state.category.state === HINTED,
    loading: state.view.state === LOADING,
  }),
  dispatch => ({
    hintProduct: p => dispatch(hintProduct(p))
  })
)
export default class CategoryPage extends PureComponent {
  render({ category, products, hinted, hintProduct, loading }: Props, _: any, { t }: Context) {
    return (
      <div class={styles.categoryPage}>
        <Infobar
          title={t('CATEGORYPAGE.TITLE', { name: category.name })} />

        <Wrapper>
          {loading &&
            <div class={styles.loading}>
              <Loader dotClass={styles.loadingDot} />
            </div>
          }
          {!loading && !products.length &&
            <div class={styles.empty}>
              <h2>{t('CATEGORYPAGE.NO_RESULT')}</h2>
              <SadFace />
            </div>
          }
          {!loading && products.length > 0 &&
            <ProductList
              class={styles.productList}
              products={products}
              hintProduct={hintProduct}/>
          }
        </Wrapper>

        <Helmet title={t('CATEGORYPAGE.TITLE', { name: category.name })}/>
      </div>
    );
  }
}

// @flow @jsx h

import { h, Component } from "preact";
import styles           from "./styles.scss";
import classNames       from "classnames";

type Props = {
  children          : Element,
  class?            : string,
  open              : boolean,
  renderDangerously : boolean
};

export default class Foldable extends Component {
  props: Props;

  setHeight = (opening: boolean) => {
    if (!this.base) {
      return;
    }

    const duration = parseFloat(window.getComputedStyle(this.base).transitionDuration) * 1000;

    this.base.style.height = `${this.base.clientHeight}px`;

    setTimeout(() => {
      if (this.base) {
        this.base.style.height = opening ? `${this.wrapper.clientHeight}px` : 0;
      }
    }, 50);

    setTimeout(() => {
      if (this.base) {
        this.base.style.height = null;
      }
    }, duration + 50);
  }

  update = (props: Props) => this.setHeight(props.open);

  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.open     !== this.props.open ||
        nextProps.children !== this.props.children) {

      this.update(nextProps);
    }
  }

  render({ children, open, renderDangerously }: Props) {
    return (
      <div
          class={classNames(
            styles.block,
            {[styles.open]: open}
          )}>

        <div
            ref={elem => this.wrapper = elem}
            class={this.props["class"]}
            dangerouslySetInnerHTML={
              renderDangerously ? { __html: children } : false
            }>

          {renderDangerously ? null : children}
        </div>
      </div>
    );
  }
}

// @flow

export const getFirstLetterBrands = (brands: Array<any>) => {
  return brands.reduce((initial, brand) => {
    const firstChar = brand.name[0].toUpperCase();
    if(!initial.includes(firstChar)) {
      initial.push(firstChar);
    }
    return initial;
  }, []);
}

export const sortBrands = (brands: Array<any>) => {
  return brands.reduce((initial: Array<Object>, brand: Object) => {
    const firstChar = brand.name[0].toUpperCase();
    const idx = initial.findIndex((o, i) => o.id === firstChar);

    if(idx !== -1) {
      initial[idx].data.push(brand);
    } else {
      initial.push({
        id: firstChar,
        data: [brand],
      });
    }

    return initial;
  }, []);
}

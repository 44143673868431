// @flow @jsx h

import { h } from 'preact';
import { PureComponent } from 'shared-components';
import { Form, Field, rules } from "formaggio";
import { connect } from 'preact-redux';
import cn from 'classnames';

import StartIcon from 'icons/start.svg';
import QuestionIcon from 'icons/question.svg';
import InputButtonField from 'components/InputButtonField';
import RadioGroup from 'components/RadioGroup';
import Loader from 'components/Loader';
import Tooltip from 'components/Tooltip';

import { loginCustomerRules } from 'helpers/validationRules';
import { getAvailableLanguages } from 'helpers/locales';

import { LOADING, load as loadCustomer } from 'reducers/customer';
import { updateData as updateUserData } from 'reducers/user';

import styles from './styles.scss';

type Props = {
  country: string,
  processing: boolean,
  loginCustomer: Function,
  processClass: string,
}

type State = {
  accountNr: string,
  country: string,
}

const validate = rules(loginCustomerRules("accountNr"));

@connect(state => ({
  country: state.user.data.defaultCountry,
  processing: state.customer.state === LOADING,
}), dispatch => ({
  loadCustomer: (customer) => dispatch(loadCustomer(customer)),
  updateUserData: (customer) => dispatch(updateUserData(customer)),
}))
export default class LoginWidget extends PureComponent {
  constructor(props: Props) {
    super(props);

    this.state = {
      accountNr: '',
      countryId: props.country,
    }
  }

  loginCustomer = (e: SyntheticEvent<HTMLButtonElement>, state: Object) => {
    e.preventDefault();

    this.props.loadCustomer({
      accountNr: this.state.accountNr,
      countryId: this.state.countryId,
    });

    this.props.updateUserData({
      currentAccountNumber: this.state.accountNr,
      currentCountryCode: this.state.countryId,
    });
  }

  handleSubmitError = (event: any, validations: any) => {
    const element = document.querySelector(`[name="${validations[0].field}"]`);

    if(element) {
      element.focus();
    }
  };

  render({ country, loginCustomer, processing }: Props, state: State, { t }: Context) {
    const radioGroupOptions = getAvailableLanguages(t);

    const validations = validate(state);

    return (
      <div class={cn(
        styles.loginWidget,
        this.props['class'] && this.props['class'],
        { [this.props['processClass']]: this.props['processClass'] && processing })} >

        <div class={cn(
          styles.primary,
          { [styles.hidden]: processing })}>

          <div class="row--small">
            <div class={styles.title}>
              <div class={styles.left}>
                <StartIcon class={styles.icon} />
                <span>{t('WIDGET.SIGN_IN_AS_CUSTOMER').toUpperCase()}</span>
              </div>
              <Tooltip
                text={t('WIDGET.TOOLTIP_SIGN_IN_CUSTOMER')}>
                <QuestionIcon />
              </Tooltip>
            </div>
          </div>

          <Form
            validations={validations}
            onSubmit={this.loginCustomer}
            onChange={x => { this.setState(x) }}
            state={this.state}>

            <div class="row--small">
              <Field
                type="text"
                name="accountNr"
                label={t('WIDGET.ENTER_CUSTOMER_NUMBER')}
                buttonIcon="arrow"
                component={InputButtonField}
              />
            </div>

            <div class="row--small">
              <Field
                name="countryId"
                class={styles.countries}
                options={radioGroupOptions}
                component={RadioGroup}
              />
            </div>

          </Form>
        </div>

        {processing &&
          <div class={styles.loader}>
            <Loader
              dotClass={styles.loadingDot} />
          </div>
        }
      </div>
    );
  }
}

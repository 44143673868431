// @flow @jsx h

import type { TProduct } from 'types/product';
import { h } from 'preact';
import Helmet from 'preact-helmet';
import { connect } from 'preact-redux';
import { PureComponent, ScrollTop } from 'shared-components';

import Wrapper from 'components/Wrapper';
import Product from 'components/Product';
import Infobar from 'components/Infobar';

import { getProductMeta } from 'helpers/getMetaData';

import styles from './styles.scss';

type Props = {
  product: Object
};

@connect(state => ({
  product: state.product,
}), {})
export default class ProductView extends PureComponent {
  render({ product }: Props) {
    const meta = getProductMeta(product);

    return (
      <div class={styles.block}>
        <Infobar
          titleClass={styles.title}
          title={`${product.product.name}, ${product.product.attributes.brand || product.product.attributes.manufacturer}`} />

        <Wrapper>
          <Product product={product} />

          <ScrollTop />

          <Helmet
            title={`${product.product.name}, ${product.product.attributes.manufacturer}`}
            meta={meta.data}
            link={meta.link}
            />

        </Wrapper>
      </div>
    );
  }
}

import _fonts      from "theme/generic/_fonts.scss";
import _normalize  from "theme/generic/_normalize.scss";
import _base       from "theme/generic/_base.scss";
import _typography from "theme/generic/_typography.scss";
import _helpers    from "theme/generic/_helpers.scss";
import _rows       from "theme/objects/_rows.scss";

import CoreJs      from "core-js";

import { h, render }              from "preact";
import { Provider }               from "preact-redux";
import { mkStore }                from "./store";
import { APP_KEY,
         Shared,
         prefixFetchWithCountry } from "./shared";
import { ConnectedRouter }        from "react-router-redux";
import { Route }                  from "react-router";
import { createBrowserHistory }   from "history";
import whatInput                  from "what-input";
import config                     from "../config.js";
import WithContext                from "containers/WithContext";
import { prefix,
         rejectOnHttpError,
         withCookies }            from "fetch-utilities";
import fetch                      from "isomorphic-fetch";
import getTranslationFromHostname from "helpers/locales";
import { watch }                  from "@crossroads/dev-server";
import { WithGoogleAnalytics }    from "redux-enhancer-google-analytics";

const root       = document.getElementById(APP_KEY);
const history    = createBrowserHistory();
const basicFetch = rejectOnHttpError(withCookies(prefix(fetch, config.apiHost.client)));
const client     =  (input: string | Request, init?: RequestOptions): Promise<Response> =>
  basicFetch(prefixFetchWithCountry(input, window[APP_KEY].getState()), init);

// Boot up watch-process in dev mode to make sure we reload
if (process.env.NODE_ENV !== "production") {
  watch({ port: 6061 });
}

window[APP_KEY] = mkStore(window[APP_KEY] && window[APP_KEY].getState ? window[APP_KEY].getState() : window[APP_KEY], history, client);

render(<div id={APP_KEY} class="root">
  <Provider store={window[APP_KEY]}>
    <WithGoogleAnalytics ga={window[APP_KEY].googleAnalytics}>
      <WithContext translation={getTranslationFromHostname(window[APP_KEY].getState().server.hostname)}>
        <ConnectedRouter history={history}>
          <Route>
            <Shared />
          </Route>
        </ConnectedRouter>
      </WithContext>
    </WithGoogleAnalytics>
  </Provider>
</div>, null, root);

// @flow @jsx h

import { h } from 'preact';
import { PureComponent } from 'shared-components';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import type { TProductDetail } from 'types/product';

import styles from './styles.scss';

type Props = {
  name: string,
  product: TProductDetail,
  brand: string,
}

export default class ProductStatus extends PureComponent {
  render({ name, brand, product }: Props, _: any, { t, formatPrice }: Context) {

    const isVirtual = product.type === 'virtual';
    const deliveryToCustomer = product.isInStock ? isVirtual ? t('PRODUCT.INSTANT') : t('PRODUCT.DELIVERY_CUSTOMER_RESULT', { days: "3-7"}) : '-';

    return (
      <div class={styles.productStatus}>

        <div class={styles.left}>

          <div class={styles.leftTop}>
            <h2 class={styles.name}>{name}</h2>
            {brand &&
              <Link class={styles.link} to={`/brand/${product.attributes.brand || product.attributes.manufacturer || ''}`}>
                {brand}
              </Link>
            }
          </div>

          {product.price &&
            <span class={styles.price}>{formatPrice(product.price)}</span>
          }
        </div>

        <div class={styles.right}>
          <div class={styles.attribute}>
            <span>{t('PRODUCT.SKU')}</span>
            <span>{`#${product.sku}`}</span>
          </div>
          <div class={styles.attribute}>
            <span>{t('PRODUCT.AVAILABILITY')}</span>

            {typeof product.isInStock !== 'undefined' &&
              <div class={styles.inStockInfo}>
                <div class={cn(
                  styles.dot,
                  { [styles['greenDot']]: product.isInStock && product.isSalable},
                  { [styles['redDot']]: !product.isInStock || !product.isSalable},)}>
                </div>
                <span>{t(product.isInStock && product.isSalable ? 'PRODUCT.IN_STOCK' : 'PRODUCT.OUT_OF_STOCK')}</span>
              </div>
            }

          </div>

          {product.isSalable && typeof product.isInStock !== 'undefined' && !product.isInStock &&
            <div class={styles.attribute}>
              <span>{t('PRODUCT.INBOUND')}</span>
              <span>{product.attributes.estimated_delivery_date}</span>
            </div>
          }

          {product.isSalable &&
            <div class={styles.attribute}>
              <span>{t('PRODUCT.DELIVERY_CUSTOMER')}</span>
              <span>{deliveryToCustomer}</span>
            </div>
          }

        </div>

      </div>

    );
  }
}

// @flow @jsx h

import { h } from 'preact';
import { PureComponent } from 'shared-components';
import cn from 'classnames';

import Thumbnail from 'components/Thumbnail';

import styles from './styles.scss';

type Props = {
  mediaGallery: Array<any>,
  onClick: Function,
}

export default class ThumbnailList extends PureComponent {
  thumbnailClicked = (e: any, idx: number) => {
    e.preventDefault();
    this.props.onClick(idx);
  }

  render({ mediaGallery }: Props) {
    const thumbnails = mediaGallery.map(x => x.thumbnail);
    return (
      <div class={cn(
        styles.thumbnailList,
        this.props['class'])}>
        {
          thumbnails.map((t, idx) => <Thumbnail key={t} id={idx} onClick={this.thumbnailClicked} class={styles.thumbnail} url={t}/>)
        }
      </div>
    );
  }
}

export const focusInvalidField = (e: Event, errors: Array<{ field: string }>) => {
  const element = e.target;
  if (e.target instanceof HTMLElement) {
    const field = e.target.querySelector(`[name='${errors[0].field}']`);
    field && field.focus();
  }
};

export const translateErrors = (errors: ?Array<{ error: string, field: string}>, t: Function) => {
    return errors ? errors.map(x => {
      // if the translation has a name for the field, translate it!
      if (t(`VALIDATION_ERRORS.${errors[0].error}`, { field: "%field" }).indexOf('%field') !== -1) {
        const fieldParts = errors[0].field.split('.');
        const field = t(`FORM_FIELDS.${fieldParts.length > 1 ? fieldParts.slice(1).join('.') : fieldParts[0]}`);
        return t(`VALIDATION_ERRORS.${errors[0].error}`, { field });
      }
      return t(`VALIDATION_ERRORS.${errors[0].error}`);
    }) : [];
  }

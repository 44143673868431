/**
 * Helpers to get metadata
 */

export function getProductMeta(product) {
  const description = product.metaDescription || product.shortDescription,
        title       = product.metaTitle       || product.name,
        image       = product.smallImage      || product.largeImage || product.originalImage;

  const meta = {
    title : title,
    data  : [{ property: "og:title", content: title }],
    link  : [{ rel: "canonical", href: `/${product.urlKey}` }]
  };

  if (description) {
    meta.data.push(
      { name     : "description",    content : description },
      { property : "og:description", content : description }
    );
  }

  if (image) {
    meta.data.push(
      { property: "og:image", content: image }
    );
  }

  return meta;
}

// @flow @jsx h

import { h } from 'preact';
import { PureComponent } from 'shared-components';
import linkstate from 'linkstate';
import styles from './styles.scss';
import ResursLogo from "icons/resursbank.svg";
import Button from "components/Button";
import InputField from "components/InputField";

import { validateLogin } from 'helpers/validationRules';

type Props = {
  onSubmit: Function,
  loggingIn: boolean
}

type State = {
  epost: {
    value: string,
    dirty: boolean,
    errors: Array<{ field: string, error: string }>,
  },
  password: {
    value: string,
    dirty: boolean,
    errors: Array<{ field: string, error: string }>,
  },
  submitted: boolean,
}

export default class LoginForm extends PureComponent {
  state = {
    epost: {
      value: "",
      dirty: false,
      errors: []
    },
    password: {
      value: "",
      dirty: false,
      errors: []
    },
    submitted: false,
  };

  onEmailChange = (e: SyntheticEvent<HTMLInputElement>) => {
    const name = e.currentTarget.name;
    const value = e.currentTarget.value;

    this.setState({...this.state,
      epost: {
        value: value,
        dirty: true,
        errors: validateLogin(name, value)
      }
    });
  }

  onPasswordChange = (e: SyntheticEvent<HTMLInputElement>) => {
    const name = e.currentTarget.name;
    const value = e.currentTarget.value;

    this.setState({...this.state,
      password: {
        value: value,
        dirty: true,
        errors: validateLogin(name, value)
      }
    });
  }

  handleSubmitError = () => {
    const firstErrorField = this.state.epost.errors.length ? 'epost' : 'password';

    const element = document.querySelector(`#${firstErrorField}`);

    if (element) {
      element.focus();
    }
  };

  submitForm = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();

    this.setState({
      ...this.state,
      submitted: true
    });

    if(this.state.epost.errors.length || this.state.password.errors.length) {
      this.handleSubmitError();
      return;
    }

    const user = {
      email: this.state.epost.value,
      password: this.state.password.value
    }

    this.props.onSubmit(user);
  }

  render(props: Props, state: State, { t }: Context) {
    return (
      <div class={styles.loginForm}>

        <div class="row--small">
          <div class={styles.logos}>
            <ResursLogo />
            <img className={styles.logo} src="/assets/images/logos/awardit.svg" alt={t("IMG_ALT.AWARDIT_LOGO")}/>
          </div>
        </div>

        <form onSubmit={this.submitForm}>
          <div class="row--small">
            <h3>{t("LOGIN.TITLE")}</h3>
          </div>

          <div class="row--small">
            <InputField
              id="epost"
              onChange={this.onEmailChange}
              errors={state.epost.errors}
              value={state.epost.value}
              submitted={state.submitted}
              name={'epost'}
              label={t("FORM.EMAIL")}
              type="text"
              autocomplete={true} />
          </div>

          <div class="row--small">
            <InputField
              id="password"
              onChange={this.onPasswordChange}
              errors={state.password.errors}
              value={state.password.value}
              submitted={state.submitted}
              name={'password'}
              label={t("FORM.PASSWORD")}
              type="password"
              autocomplete={true} />
          </div>

          <div class="row">
            <Button
              modifiers="primary wide"
              processing={props.loggingIn}
              type="submit">
              {t("LOGIN.SUBMIT")}
            </Button>
          </div>

        </form>
      </div>
    )
  }
}

// @flow

import en_GB from "translations/en_GB";

export default function getTranslationFromHostname(hostname: string) {
  return en_GB;

  // switch (hostname.split(".").pop()) {
  //   // case "se" : return sv_SE;
  //   default   : return en_GB;
  // }
}

export function getAvailableLanguages(t: function) {
  return [
    { value: 'SE', text: t('COUNTRY.SE') },
    { value: 'DK', text: t('COUNTRY.DK') },
    { value: 'FI', text: t('COUNTRY.FI') },
    { value: 'NO', text: t('COUNTRY.NO') }
  ];
}

export function mapShortToCountry(short: string, t: function): ({ text: string, value: string } | boolean) {
  const countries = getAvailableLanguages(t);

  const country = countries.find(c => c.value === short);

  if(typeof country === 'undefined') {
    return false;
  } else {
    return country;
  }
}

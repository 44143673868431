// @flow @jsx h

import type { TProductDetail } from 'types/product';
import { h } from 'preact';
import { PureComponent } from 'shared-components';
import { connect } from "preact-redux";

import ProductImages from 'components/ProductImages';
import ProductInfo from 'components/ProductInfo';

import { add as addToCart, ADDING } from "store-reducers/cart";
import { LOADED as CUSTOMER_LOADED, NOT_FOUND as CUSTOMER_NOT_FOUND } from 'reducers/customer';

import { setOption,
  setFormData }    from "store-reducers/product";
import { LOADING as VIEW_LOADING } from 'store-reducers/view';

import styles from './styles.scss';

type Props = {
  product: any,
  productForm: Object,
  setOption: Function,
  hasSelect: boolean,
  adding: boolean,
  customerLoaded: boolean,
  viewLoading: boolean,
  location: boolean,
  pathname: string,
};

@connect(state => ({
  customerLoaded: state.customer.state === CUSTOMER_LOADED || state.customer.state === CUSTOMER_NOT_FOUND,
  productForm: state.product.form,
  hasSelection: Object.keys(state.product.form.attributes).length > 0,
  adding: state.cart.state === ADDING,
  viewLoading: state.view.state === VIEW_LOADING,
  pathname: state.router.location ? state.router.location.pathname : state.server.pathname,
}), dispatch => ({
  setOption: (o, v) => dispatch(setOption(o, v)),
  addToCart: (d) => dispatch(addToCart(d)),
}))
export default class Product extends PureComponent {
  addToCart = () => {
    if (this.submitDisabled()) {
      return;
    }

    this.props.addToCart({
      ...this.props.productForm,
      qty: this.props.productForm.qty || 1,
      product: this.props.product.product,
    });
  }

  submitDisabled = () => {
    const product = this.props.product;

    return this.props.adding || (product.type === "configurable" && !this.props.hasSelection);
  }

  render({ product, productForm, setOption, customerLoaded, viewLoading, adding, pathname }: Props) {

    const entering = pathname.substr(1) === product.product.urlKey;
    const options = product.product.options || [];
    const attributes = product.product.attributes || {};
    const brand = attributes.brand || attributes.manufacturer;

    const selectedItem = product.product.type === "configurable" && options.length > 0 ?
      Object.keys(productForm.attributes).length > 0 ?
      options[0].values.filter(v => v.id === productForm.attributes[options[0].id])[0]
      : product.product
      : product.product;

    const buttonEnabled = product.product.type === "configurable" && options.length > 0 ?
      Object.keys(productForm.attributes).length > 0
      : true;

    return (
      <div class={styles.product}>

        <div class={styles.left}>
          <ProductImages product={product} />
        </div>

        <div class={styles.right}>
          <ProductInfo
            processing={(viewLoading && entering) || adding}
            brand={brand}
            name={product.product.name}
            formData={productForm}
            product={selectedItem}
            buttonEnabled={buttonEnabled}
            addToCart={this.addToCart}
            setOption={setOption}
            setFormData={setFormData}
            options={options}
            customerLoaded={customerLoaded}
             />
        </div>

      </div>
    );
  }
}

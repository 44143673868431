// @flow @jsx h

import { h } from 'preact';
import { PureComponent } from 'shared-components';

import type { TCartItem } from 'types/cart';
import CartList from 'components/CartList';
import CartSummary from 'components/CartSummary';
import Button from 'components/Button';

import styles from './styles.scss';

type Props = {
  setQty: Function,
  items: Array<TCartItem>,
  hintProduct: Function,
  submitting: boolean,
};

export default class Cart extends PureComponent {
  render({ setQty, items, hintProduct, submitting }: Props, _: any, { t }: Context) {
    return (
      <div class={this.props['class']}>
        <div class="row--small">
          <h4 class={styles.title}>
            {t('CHECKOUTPAGE.CART_TITLE')}
          </h4>
        </div>
        <div class="row--small">
          <CartList
            setQty={setQty}
            items={items}
            hintProduct={hintProduct} />
        </div>
        <div class="row--small">
          <CartSummary
            items={items} />
        </div>
        <div class="row--small">
          <Button
            processing={submitting}
            type="submit"
            modifiers="primary wide">
            {t('CHECKOUTPAGE.SUBMIT')}
          </Button>
        </div>
      </div>
    )
  }
}

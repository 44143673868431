// @flow @jsx h

import type { TCustomer } from 'types/customer';
import { h } from 'preact';
import { PureComponent } from 'shared-components';
import cn from 'classnames';

import Button from 'components/Button';

import IconFlagSE from 'icons/flag_se.svg';
import IconFlagDK from 'icons/flag_dk.svg';
import IconFlagFI from 'icons/flag_fi.svg';
import IconFlagNO from 'icons/flag_no.svg';

import styles from './styles.scss';

const ICONS = {
  SE: IconFlagSE,
  DK: IconFlagDK,
  FI: IconFlagFI,
  NO: IconFlagNO,
}

type Props = {
  name: string,
  customerLoggedIn: boolean,
  country: string,
  onLogout: Function
}

export default class SidebarFooter extends PureComponent {
  logout = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();
    this.props.onLogout();
  }

  render({ name, customerLoggedIn, country, onLogout }: Props, _: any, { t }: Context) {
    const FlagComponent = country && ICONS[country];
    return (
      <div class={styles.block}>
        <div class={styles.info}>
          <div class="row--mini">
            {name}
          </div>
          <div class={styles.customerInfo}>
              <div class={cn("row--mini", styles.row)}>
                <div class={cn(
                  styles.dot,
                  {[styles.active]: customerLoggedIn}
                )}/>
                <span class={cn(
                  styles.text,
                  {[styles.activeText]: customerLoggedIn}
                )}>
                  {customerLoggedIn ? t('SIDEBAR.CUSTOMER_SIGNED_IN') : t('SIDEBAR.CUSTOMER_NOT_SIGNED_IN')}
                </span>
              </div>
              <div class={cn("row--mini", styles.row)}>
                <FlagComponent />
                <span class={styles.text}>{t('SIDEBAR.SHOP', { country: country.toUpperCase() })}</span>
              </div>
          </div>
        </div>
        <Button
          class={styles.button}
          onClick={this.logout}>
          {t('SIDEBAR.SIGN_OUT')}
        </Button>
      </div>
    );
  }
}

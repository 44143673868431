// @flow @jsx h

import { h }             from "preact";
import { PureComponent } from "shared-components";

import Dropdown from "components/Dropdown";

type Props = {
  class?:        string,
  option:        Object,
  selectedValue: Object,
  direction:     string,
  setOption:     Function
};

export default class ProductOption extends PureComponent {
  setOption = (selectedValue: Object) => {
    this.props.setOption(this.props.option.id, selectedValue);
  }

  render({ option, selectedValue, direction = "down", ...rest }: Props, state: any, { t }: Context) {
    return (
      <Dropdown
          {...rest}
          onSelect={this.setOption}
          options={option.values}
          value={selectedValue || null}
          direction={direction}
          labelKey="label"
          valueKey="id"
          label={t("PRODUCT.OPTION", { attr: option.title.toLowerCase() })}
      />
    );
  }
}

export default function formatPrice(locale) {
  if (!toLocaleStringSupportsLocales()) {
    return (n) => n + " " + locale.symbol;
  }

  const formatter = new Intl.NumberFormat(locale.id, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const formatterWithoutDeciamals = new Intl.NumberFormat(locale.id, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

  return (price) =>
    typeof price !== "number" ? null :
      (price % 1 !== 0 ?
        formatter.format(price) :
        formatterWithoutDeciamals.format(price)) + " " + locale.symbol;
}

function toLocaleStringSupportsLocales() {
  try {
    new Date().toLocaleString("i");
  } catch (e) {
    return e instanceof RangeError;
  }

  return false;
}

// @flow @jsx h

import type { TProduct } from 'types/product';
import { h } from 'preact';
import { PureComponent } from 'shared-components';
import { connect } from 'preact-redux';

import ProductList from 'components/ProductList';
import SadFace from 'icons/face_sad.svg';

import { LOADED as SEARCH_LOADED } from 'reducers/search';
import { hintProduct } from "store-reducers/product";

import styles from './styles.scss';

type Props = {
  searched: boolean,
  result: Object,
  hintProduct: Function
}

@connect(state => ({
  searched: state.search.state === SEARCH_LOADED,
  result: state.search.result,
}), dispatch => ({
  hintProduct: (p) => dispatch(hintProduct(p)),
}))
export default class SearchResult extends PureComponent {
  render({ result, searched, hintProduct }: Props, _: any, { t }: Context) {
    return (
      <div class={styles.block}>
        {searched &&
          <div class={styles.info}>
            <span class={styles.query}>{`"${result.query}"`}</span>
            <span class={styles.result}>{t('SEARCHPAGE.RESULT', { num: result.totalCount || 0, query: result.query})}</span>
          </div>
        }
        {result.products && result.products.length > 0 &&
          <div class={styles.products}>
            <ProductList products={result.products} hintProduct={hintProduct}/>
          </div>
        }
        {result.products && result.products.length === 0 &&
          <div class={styles.icon}>
            <SadFace />
          </div>
        }
      </div>
    );
  }
}

// @flow @jsx h

import { h } from 'preact';
import { PureComponent } from 'shared-components';
import cn from "classnames";
import styles from './styles.scss';
import { translateErrors } from '../../helpers/formHelpers';

type Props = {
  class?: string,
  hasError: boolean,
  errors: Array<{ field: string, error: string }>,
  label: string,
  labelClass: string,
  dirty: boolean,
  submitted: boolean,
  autocomplete: boolean,
}

export default class InputField extends PureComponent {
  static defaultProps = {
    modifiers: ""
  }

    /*
   Prevent Preact from re-using the DOM element.
   This will fix the password re-using bug:
   https://stackoverflow.com/questions/23156578/google-chrome-autofilling-all-password-inputs
  */
  removeBase = (el: any) => {
    if (!el) {
      this.nextBase = null;
    }
  }


  render({ hasError, errors, label, labelClass, autocomplete = false, ...props }: Props, _: any, { t }: Context) {
    const translatedErrors = translateErrors(errors, t);

    return (
      <div class={cn(
        styles.inputfield,
        props['class'],
      )}>

        {label && label.length > 0  &&
          <label
            class={cn(
              styles.label,
              {[styles['labelInvalid']]: (props.dirty || props.submitted) && translatedErrors && translatedErrors[0] },
              labelClass
            )}>
            {label}
          </label>
        }

        <input
          {...props}
          autocomplete={autocomplete? 'on' : 'nope'}
          ref={this.removeBase}
          class={cn(
            props["class"],
            styles.field,
            {[styles['invalid']]: (props.dirty || props.submitted) && translatedErrors && translatedErrors[0] }
          )}
        />

        {(props.dirty || props.submitted) && translatedErrors && translatedErrors[0] &&
          <div class={styles.error}>
            <p class={styles.errorMessage}>{translatedErrors[0]}</p>
          </div>
        }

      </div>
    )
  }
}

// @flow @jsx h

import { h } from 'preact';
import { PureComponent } from 'shared-components';
import { Field } from "formaggio";
import cn from 'classnames';

import InputField from 'components/InputField'
import Button from 'components/Button';

import styles from './styles.scss';

type Props = {
  label?: string,
  name: string,
  processing?: boolean,
  buttonText?: string,
  buttonIcon?: string,
  dirty: boolean,
  submitted: boolean,
  errors: Array<{ field: string, error: string }>,
}

export default class InputButtonField extends PureComponent {
  render({ errors, label, name, processing, buttonText, buttonIcon, ...props }: Props) {
    return (
      <div class={styles.block}>

        {label && label.length > 0 &&
          <label class={cn(
            styles.label,
            {[styles['labelInvalid']]: (props.dirty || props.submitted) && errors && errors.length },
          )}>
            {label}
          </label>
        }

        <div class={styles.input}>
          <InputField
            type="text"
            errors={errors}
            {...props}
            name={name}
            class={styles.field}
          />
          <Button
            modifiers="tiny primary"
            class={styles.button}
            processing={processing}
            type="submit"
            icon={buttonIcon}
            iconClass={styles.iconClass}>
            {buttonText}
          </Button>
        </div>

      </div>
    );
  }
}

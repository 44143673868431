// @flow @jsx h

import type { TCartItem } from 'types/cart';
import { h } from 'preact';
import { PureComponent } from 'shared-components';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import Thumbnail from 'components/Thumbnail';
import Button from 'components/Button';
import InputField from 'components/InputField';
import QtyPicker from 'components/QtyPicker';

import styles from './styles.scss';


const MAX_QTY   = 999;

type Props = {
  item: TCartItem,
  hintProduct: Function,
  setQty: Function,
}

export default class CartListItem extends PureComponent {
  getThumbnail = (item: TCartItem) => {
    if(item.options) {
      return item.options[0].value.thumbnail;
    } else {
      return item.product.thumbnail;
    }
  }

  getOption = (item: TCartItem) => {
    if(item.options) {
      return {
        title: item.options[0].title,
        label: item.options[0].value.label
      }
    } else {
      return false;
    }
  }

  decrement = () => {
    if(this.props.item.qty - 1 <= 0) {
      return;
    }
    this.props.setQty(this.props.item, this.props.item.qty - 1);
  }

  increment = () => {
    if(this.props.item.qty + 1 > MAX_QTY) {
      return;
    }
    this.props.setQty(this.props.item, this.props.item.qty + 1);
  }

  remove = () => {
    this.props.setQty(this.props.item, 0);
  }

  render({ item, hintProduct, setQty }: Props, _: any, { t, formatPrice }: Context) {
    const thumbnail = this.getThumbnail(item);
    const option = this.getOption(item);

    return (
      <div class={cn(styles.cartListItem, this.props['class'])}>

          <ItemDescription
            item={item}
            thumbnail={thumbnail}
            option={option}
            hintProduct={hintProduct}
            t={t}
            formatPrice={formatPrice} />

          <hr class={styles.hr} />

          <ItemActions
            class={styles.cartListItemActions}
            value={item.qty}
            t={t}
            increment={this.increment}
            decrement={this.decrement}
            remove={this.remove}
            />

      </div>
    );
  }
}

type ItemDescriptionProps = {
  item: TCartItem,
  hintProduct: Function,
  thumbnail: string,
  t: Function,
  option: { title: string, label: string} | boolean,
  formatPrice: Function,
}

const ItemDescription = ({ item, hintProduct, thumbnail, option, t, formatPrice }: ItemDescriptionProps) => {

  const handleClick = (event: any) => {
    if (!item.product.urlKey) {
      event.preventDefault();
      return;
    }

    if (typeof hintProduct === 'function') {
      hintProduct(item.product);
    }
  }

  const brand = item.product.attributes.brand || item.product.attributes.manufacturer;

  return (
    <Link
      to={"/" + item.product.urlKey}
      onClick={e => handleClick()}
      class={styles.itemDescription}>

          <div class={styles.thumbnail}>
            <Thumbnail url={thumbnail} />
          </div>

          <div class={styles.descr}>

            <div class={styles.top}>
              <span class={styles.name}>
                {item.product.name}
              </span>
              <span class={styles.price}>{`${formatPrice(item.product.price)}`}</span>
            </div>

            <div class={styles.bottom}>
              <div class={styles.bottomLeft}>
                {option && typeof option !== 'boolean' &&
                  <div>
                    <span>{option.title}:</span>
                    <span class={styles.optionLabel}>{option.label}</span>
                  </div>
                }
                <span>{brand}</span>
              </div>
              <div>
                <span>{`#${item.product.sku}`}</span>
              </div>
            </div>

          </div>
        </Link>
  )
}

type ItemActionProps = {
  value: number,
  increment: Function,
  decrement: Function,
  remove: Function,
  class: string,
  t: Function,
}

const ItemActions = ({ value, increment, decrement, remove, t }, props: ItemActionProps) => {
  return (
    <div class={cn(
      styles.itemActions,
      props['class'])}>
      <Button
        onClick={remove}
        class={styles.removeButton}
        icon="trashbin"
        iconPosition="before" >
        <span class={styles.removeButtonText}>
          {t('CHECKOUTPAGE.REMOVE')}
        </span>
      </Button>

      <QtyPicker value={value} increment={increment} decrement={decrement} />
    </div>
  )
}



// @flow

/**
 * Returns the basesegment of given path
 *
 * @param {string} path
 */
export const getRootSegment = (path: string): string => {
  return path.replace(/\//g, ' ').trim().split(/\s/)[0];
}

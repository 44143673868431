// @flow @jsx h

import type { TCategory } from 'types/category'

import { h } from 'preact';
import Helmet from 'preact-helmet';
import { connect } from 'preact-redux';

import { PureComponent } from 'shared-components';
import { hintCategory } from 'store-reducers/category';
import { loadAll as loadAllCategories, LOADED as CATEGORIES_LOADED } from 'store-reducers/categories';

import { filterAndSortMainCategories } from 'helpers/categories';

import Wrapper from 'components/Wrapper';
import Infobar from 'components/Infobar';
import Section from 'components/Section';

import styles from './styles.scss';

type Props = {
  categories: { data: Array<TCategory> },
  sortedCategories: Array<TCategory>,
  categoriesLoaded: boolean,
  loadCategories: Function,
};

@connect(state => ({
  categories: state.categories,
  sortedCategories: state.categories && state.categories.data && filterAndSortMainCategories(state.categories.data),
  categoriesLoaded: state.categories.state === CATEGORIES_LOADED,
}),
  dispatch => ({
    hintCategory: c => dispatch(hintCategory(c)),
    loadCategories: () => dispatch(loadAllCategories()),
  })
)
export default class CategoriesPage extends PureComponent {
  componentWillMount() {
    if(!this.props.categoriesLoaded) {
      this.props.loadCategories();
    }
  }

  render({ categories, sortedCategories }: Props, _2: any, { t }: Context) {
    return (
      <div class={styles.categoriesPage}>

        <Infobar title={t('INFOBAR.CATEGORIES')} />

        <Wrapper>
          <div class={styles.container}>

            {sortedCategories && sortedCategories.length > 0 &&
              sortedCategories.map(c => {

                const subCategories = categories.data.filter((sc: TCategory) => sc.includeInMenu && sc.parentId === c.id);

                return (
                  <Section
                    headerClass={styles.sectionHeader}
                    head={c}
                    linkClick={this.props.hintCategory}
                    linkClass={styles.sectionLink}
                    childrens={subCategories}
                    linkAttribute="url" />
                );
              })
            }

          </div>
        </Wrapper>

        <Helmet title={t('CATEGORIESPAGE.TITLE')}/>
      </div>
    );
  }
}

// @flow

import { isPromise } from 'diskho';
import { replace }   from "react-router-redux";
import {
  LOADED as CART_LOADED,
} from "store-reducers/cart";

import { LOADED as USER_LOADED } from 'reducers/user';
import { load as loadCustomer } from 'reducers/customer';
import { load as loadCart } from 'store-reducers/cart';

import {
  addStatus,
} from "store-reducers/messages";

const sideeffects = (store: any) => (next: Function) => (action: any) => {
  next(action);

  // show message when product added to cart
  if (action.type === CART_LOADED) {
    if (isPromise(action.payload)) {
      if (action.addId) {
        return action.payload.then(x => next(addStatus("success", "PRODUCT_ADDED")));
      }
    }
  } else if (action.type === USER_LOADED) {
    // load customer if currentAccountNumber and currentCountryCode are available
    if(isPromise(action.payload)) {
      return action.payload.then(r => {
        if(r && r.currentAccountNumber && r.currentCountryCode) {
          next(loadCustomer({
            accountNr: r.currentAccountNumber,
            countryId: r.currentCountryCode
          }));
          next(loadCart());
        }

      });
    }
  }
};


export default sideeffects;

// @flow @jsx h

import { h } from "preact";
import { PureComponent } from "shared-components";
import styles from "./styles.scss";
import classNames from "classnames";
import Radio from 'components/Radio';

type Props = {
  options: Array<{ value: string, text: string }>,
  onChange?: Function,
  value: any,
  name: string
};

type Option = {
  value: string,
  text: string
}

export default class RadioGroup extends PureComponent {
  render({ options, onChange, value, name }: Props, _: any, { t }: Context) {
  return (
      <div
        onChange={onChange}
        class={classNames(
          this.props["class"],
          styles.block
        )}>

        {options.map((x: Option) => <Radio key={x.value} name={name} value={x.value} checked={x.value === value} text={x.text} />)}
      </div>
    );
  }
}

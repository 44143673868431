// @flow

/**
 * user.js
 * Reducers for handling the helpdesk-user
 */

import type { Fetch } from "fetch-utilities";
import { jsonResponse, sendJson } from "fetch-utilities";
import { closeAll as closeAllMessages } from "store-reducers/messages";

import { empty as emptyCart } from 'store-reducers/cart';
import { terminate as terminateCheckout } from 'store-reducers/checkout';

export const PENDING = 'store/user/PENDING';

export const LOGGING_IN = 'store/user/LOGGING_IN';
export const LOGGED_IN = 'store/user/LOGGED_IN';

export const LOGGING_OUT = 'store/user/LOGGING_OUT';
export const LOGGED_OUT = 'store/user/LOGGED_OUT';

export const LOGOUT = 'store/user/LOGOUT';

export const LOADING = 'store/user/LOADING';
export const LOADED = 'store/user/LOADED';
export const ERROR   = "store/user/ERROR";

export const UPDATING   = "store/user/UPDATING";
export const UPDATED   = "store/user/UPDATED";


const initialState = {
  state:                   PENDING,
  data:                    {},
  loggedIn:                false
};

export default function reducer(state: Object = initialState, action: Object = {}) {
  switch(action.type) {
    case LOGGING_IN:
    case LOGGING_OUT:
    case LOADING:
      return {
        ...state,
        state: action.type
      }

    case LOGGED_IN:
    case LOADED:
      return {
        ...state,
        state: action.error ? ERROR : action.type,
        error: action.error,
        data: action.error ? state.data : action.payload || initialState.data,
        errorData: action.error ? action.payload : null,
        loggedIn: !action.error && action.payload ? true : state.loggedIn
      }

    case UPDATING:
      return {
        ...state,
        state: action.type,
      }

    case UPDATED:
      return {
        ...state,
        state: action.error ? ERROR : action.type,
        error: action.error,
        data: action.error ? state.data : action.payload || initialState.data,
        errorData: action.error ? action.payload : null,
      }

    case LOGOUT:
      return initialState;

    default:
      return state;
   }
 }

 export const load = () => (dispatch: Function, _getState: Function, client: Object) => {
  dispatch({
    type: LOADING,
  });

  dispatch({
    type: LOADED,
    payload: client("/resurs_employee").then(jsonResponse).catch(err => ! err.httpOk && err.httpStatus === 404 ? null : Promise.reject(err))
  })
};


export const login = (data: Object) => (dispatch: Function, _getState: Function, client: Fetch) => {
  dispatch({
    type: LOGGING_IN
  });

  dispatch({
    type: LOGGED_IN,
    payload: client("/resurs_employee", sendJson({ body: data })).then(x => {
      dispatch(closeAllMessages());
      return jsonResponse(x);
    })
  });
}

export const logout = () => (dispatch: Function, _getState: Function, client: Fetch) => {
  dispatch({
    type: LOGGING_OUT,
  })

  dispatch({
    type: LOGOUT,
    payload: client("/resurs_employee", { method: "DELETE" })
  });
}

export const updateData = (data: Object) => (dispatch: Function, _getState: Function, client: Fetch) => {
  dispatch({
    type: UPDATING
  });

  dispatch({
    type: UPDATED,
    payload: client("/resurs_employee",  sendJson({ method: 'PUT', body: data })).then(jsonResponse)
  })
}

// @flow @jsx h

import type { Node } from 'preact';
import { h } from 'preact';
import { PureComponent } from 'shared-components';
import styles from './styles.scss';

type Props = {
  children: Node,
  text: string,
}

type State = {
  visible: boolean,
}

export default class Tooltip extends PureComponent {
  static state = {
    visible: false,
  }

  onEnter = () => {
    this.setState({
      visible: true
    });
  }

  onLeave = () => {
    this.setState({
      visible: false
    });
  }

  render({ children, text, ...props}: Props, { visible }: State) {
    return (
      <div
        class={styles.tooltip}
        tabIndex={0}
        onFocus={this.onEnter}
        onFocusOut={this.onLeave}
        onMouseEnter={this.onEnter}
        onMouseLeave={this.onLeave}>
        {visible &&
          <div class={styles.tip}>{text}</div>
        }
        {children}
      </div>
    )
  }
}

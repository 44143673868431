// @flow @jsx h

import { h, Component }  from "preact";
import { Route, Switch, Redirect } from "react-router";
import { connect }       from "preact-redux";

import App             from "containers/App";
import RouteView       from "containers/RouteView";
import FrontPage       from "containers/FrontPage";
import LoginPage       from "containers/LoginPage";
import SearchPage      from "containers/SearchPage";
import CategoriesPage  from "containers/CategoriesPage";
import BrandsPage      from "containers/BrandsPage";
import CheckoutPage    from "containers/CheckoutPage";
import BrandView       from "containers/BrandView";
import CheckoutSuccess from "containers/CheckoutSuccess";

type Props = {
  location: Location,
  loggedIn: boolean,
};

@connect(state => ({
  loggedIn: state.user.loggedIn
}))
export default class Routes extends Component {
  render({ location, loggedIn }: Props) {

    if(!loggedIn && location && location.pathname !== "/"){
      return (
        <Redirect to="/"/>
      )
    }

    return (
      <App location={location}>
        <Switch location={location}>
          {!loggedIn && <Route path="/" exact component={LoginPage} />}
          {loggedIn && location && location.pathname === "/" && <Redirect to="/start" />}
          {loggedIn && <Route path="/start/:query?" exact component={FrontPage} />}
          {loggedIn && <Route path="/search/:query?" exact component={SearchPage} />}
          {loggedIn && <Route path="/categories" exact component={CategoriesPage} />}
          {loggedIn && <Route path="/brands" exact component={BrandsPage} />}
          {loggedIn && <Route path="/brand/:urlKey" exact component={BrandView} />}
          {loggedIn && <Route path="/checkout" exact component={CheckoutPage} />}
          {loggedIn && <Route path="/checkout/success" exact component={CheckoutSuccess} />}
          {loggedIn && <Route path="/(.+)" component={RouteView} />}
        </Switch>
      </App>
    );
  }
}

// @flow @jsx h

import type { TCategory } from 'types/category';
import type { TBrand } from 'types/brand';
import { h } from 'preact';
import { Link } from 'react-router-dom';
import { PureComponent } from 'shared-components';
import cn from 'classnames';

import LinkGrid from 'components/LinkGrid';
import styles from './styles.scss';

type Props = {
  head: string | TCategory,
  childrens: Array<TCategory | TBrand>,
  linksPerRow?: number,
  id?: string,
  headerClass?: string,
  linkClass?: string,
  urlPrefix?: string,
  linkAttribute: string,
  linkClick?: Function,
}

export default class Section extends PureComponent {
  handleLinkGridClick = (name: string | number) => {
    if(typeof this.props.linkClick === 'function') {
      const entity = this.props.childrens.find(c => c.name === name);
      this.props.linkClick(entity);
    }
  }

  handleHeadClicked = (e: SyntheticEvent<HTMLLinkElement>, entity: TCategory) => {
    if(typeof this.props.linkClick === 'function') {
      this.props.linkClick(entity);
    }
  }

  render({head, headerClass, childrens, linkClass, linksPerRow = 3, id, urlPrefix, linkAttribute}: Props) {
    const headElement = (<h2 class={cn(styles.header, headerClass)}>{typeof head === 'string' ? head : head.name}</h2>);
    const links = childrens.map(c => {
      return {
        name: c.name,
        link: `${urlPrefix ? `${urlPrefix}/` : ''}${c[linkAttribute]}`,
      }
    });

    return (
      <div id={id}
        class={styles.section}>

        <div class={styles.top}>

          {typeof head === 'string' &&
            headElement
          }

          {typeof head !== 'string' &&
            <Link class={styles.headerLink} to={head.url} onClick={e => this.handleHeadClicked(e, head)}>{headElement}</Link>
          }

        </div>

        <LinkGrid
          class={styles.linkGrid}
          linkClass={cn(styles.link, linkClass)}
          linkClick={typeof this.props.linkClick === 'function' ? this.handleLinkGridClick : null}
          links={links}
          linksPerRow={linksPerRow}/>

      </div>
    );
  }
}

// @flow

import type { TAddress } from "types/address";
import type { TCustomer, TResursCustomer } from "types/customer";
import type { Fetch }     from "fetch-utilities";

import { jsonResponse, sendJson } from "fetch-utilities";
import { push }                   from "react-router-redux";
import {
  addStatus,
  closeAll as closeAllMessages
} from "store-reducers/messages";

import { empty as emptyCart } from 'store-reducers/cart';
import { terminate as terminateCheckout } from 'store-reducers/checkout';

import { LOGOUT as USER_LOGOUT, updateData as updateUserData } from 'reducers/user';

export const PENDING        = "store/customer/PENDING";
export const LOADING        = "store/customer/LOADING";
export const LOADED         = "store/customer/LOADED";
export const ERROR          = "store/customer/ERROR";
export const UNLOADING      =  "store/customer/UNLOADING";
export const UNLOADED       = "store/customer/UNLOADED";
export const RESET          = "store/customer/RESET"
export const SHOWED_SUCCESS = "store/customer/SHOWED_SUCCESS";

export const NOT_FOUND            = "store/customer/NOT_FOUND";
export const HIDE_UNLOAD_SUCCESS  = "store/customer/HIDE_UNLOAD_SUCCESS";

const initialState = {
  state:              PENDING,
  data:               {},
  loaded:             false,
  showUnloadSuccess:  false,
  error:              false,
  errorData:          null,
};

export default function reducer(state: Object = initialState, action: Object = {}) {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        state: action.type,
        data:  action.payload,
        showUnloadSuccess: false,
      };

    case LOADED:
      return {
        ...state,
        state:     action.payload.error ? (action.payload.error.httpStatus === 404 ? NOT_FOUND : ERROR) : action.type,
        error:     action.payload.error && action.payload.error.httpStatus !== 404 ? action.payload.error : false,
        data:      action.payload.error ? state.data : { ...state.data, ...action.payload } || initialState.data,
        errorData: action.payload.error && action.payload.error.httpStatus !== 404 ? action.payload : null,
        loaded:    action.payload && (!action.payload.error || action.payload.error.httpStatus === 404)  ? true : state.loaded,
      };

    case NOT_FOUND:
      return {
        ...state,
        state: action.type,
      }

    case UNLOADING:
      return {
        ...state,
        state: action.type,
      }

    case UNLOADED:
      return {
        ...state,
        state: action.type,
        loaded: false,
        data: {},
        showUnloadSuccess: true,
      }

    case HIDE_UNLOAD_SUCCESS:
      return {
        ...state,
        showUnloadSuccess: false,
      }

    case USER_LOGOUT:
    case RESET:
      return initialState;

    default:
      return state;
  }
}

export const load = (customer: { accountNr: number, countryId: string}) => (dispatch: Function, _getState: Function, client: Object) => {
  dispatch({
    type: LOADING,
    payload: customer,
  });

  dispatch({
    type: LOADED,
    payload: client(`/resurs_addresses/${customer.accountNr}`).then(jsonResponse).catch(x => {
      return { error: x };
    })
  });
};

export const unload = () => (dispatch: Function) => {
  dispatch({
    type: UNLOADING,
  });

  const accountPutData = {
    currentCountryCode: "",
    currentAccountNumber: "",
  };

  dispatch(updateUserData(accountPutData));

  dispatch({
    type:     UNLOADED,
    payload: new Promise((resolve, reject) => {
      dispatch(emptyCart());
      dispatch(terminateCheckout());

      setTimeout(() => {
        dispatch(hideUnloadSuccess())
      }, 3000);

      resolve();
    })
  });
};

export const reset = () => ({
  type: RESET
});

export const hideUnloadSuccess = () => ({
  type: HIDE_UNLOAD_SUCCESS,
})

// @flow @jsx h

import { h } from 'preact';
import { PureComponent } from 'shared-components';

import type { TCartItem } from 'types/cart';
import CartListItem from 'components/CartListItem';

import styles from './styles.scss';

type Props = {
  setQty: Function,
  items: Array<TCartItem>,
  hintProduct: Function,
}

export default class CartList extends PureComponent {
  render({ items, setQty, hintProduct }: Props) {
    return (
      <div>
        {items && items.length > 0 && setQty && hintProduct &&
          items.map(i => {
            return (
              <CartListItem
                setQty={setQty}
                class={styles.cartListItem}
                item={i}
                hintProduct={hintProduct} />
            )
          })
        }
      </div>
    );
  }
}

// @flow @jsx h

import { h } from 'preact';
import { PureComponent } from 'shared-components';

import type { TCartItem } from 'types/cart';

import styles from './styles.scss';

type Props = {
  items: Array<TCartItem>,
}

export default class CartSummary extends PureComponent {
  render({ items }: Props, _: any, { t, formatPrice }: Context) {
    const total = items.reduce((a, i) => a + i.rowTotal, 0);

    return (
      <div class={styles.cartSummary}>
        <h4 class={styles.title}>
          {t('CHECKOUTPAGE.SUMMARY_TITLE')}
        </h4>
        <div>
          {items &&
            <table class={styles.table}>
              {
                items.map(i => {
                  const name = i.options ? `${i.product.name} - ${i.options[0].value.label}`: i.product.name;
                  return (
                    <tr>
                      <td>{i.qty}</td>
                      <td>x</td>
                      <td>{name}</td>
                      <td class={styles.points}>
                        {formatPrice(i.rowTotal)}
                      </td>
                    </tr>
                  )
                })
              }
            </table>
          }
        </div>
        <div class={styles.total}>
          <span>{t('CHECKOUTPAGE.TOTAL_POINTS')}</span>
          <span class={styles.totalPoints}>{formatPrice(total)}</span>
        </div>
      </div>
    );
  }
}

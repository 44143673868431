// @flow @jsx h

import type { TCustomer } from 'types/customer';
import { h } from 'preact';
import { PureComponent } from 'shared-components';
import Helmet from "preact-helmet";
import cn from 'classnames';
import { connect } from 'preact-redux';
import { push } from "react-router-redux";

import Infobar from 'components/Infobar';
import LoginWidget from 'components/LoginWidget';
import LogoutWidget from 'components/LogoutWidget';
import SearchWidget from 'components/SearchWidget';
import Wrapper from 'components/Wrapper';
import SearchResult from 'components/SearchResult';

import {
  search as searchProduct,
  clear as clearSearch,
  PENDING as SEARCH_PENDING,
  LOADED as SEARCH_LOADED } from 'reducers/search';

  import { LOADED as CUSTOMER_LOADED, NOT_FOUND as CUSTOMER_NOT_FOUND } from 'reducers/customer';

import styles from './styles.scss';

type Props = {
  customerLoaded: boolean,
  clearSearch: Function,
  searchPending: boolean,
  pushRoute: Function,
  searched: boolean,
  searchProduct: Function,
  match: any,
};

@connect(state => ({
  customerLoaded: state.customer.state === CUSTOMER_LOADED || state.customer.state === CUSTOMER_NOT_FOUND,
  searchPending: state.search.state === SEARCH_PENDING,
  searched: state.search.state === SEARCH_LOADED,
}), dispatch => ({
  clearSearch: () => dispatch(clearSearch()),
  pushRoute: (query) => dispatch(push(`/start/${query}`)),
  searchProduct: (query) => dispatch(searchProduct(query)),
}))
export default class FrontPage extends PureComponent {
  componentWillMount() {
    if (this.props.match.params.query && this.props.searchPending) {
      this.props.searchProduct(this.props.match.params.query);
    }
  }

  componentWillRecieveProps(nextProps: Props) {
    if (nextProps.match.params.query !== this.props.match.params.query) {

      if (nextProps.match.params.query){
        this.props.searchProduct(nextProps.match.params.query);
      } else {
        this.props.clearSearch();
      }
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.match.params.query !== this.props.match.params.query) {
      if (this.props.match.params.query) {
        this.props.searchProduct(this.props.match.params.query);
      } else {
        this.props.clearSearch();
      }
    }
  }

  componentWillUnmount() {
    this.props.clearSearch();
  }

  render({ customerLoaded, searched, pushRoute }: Props, _: any, { t }: Context) {
    return (
      <div class={styles.frontPage}>
        <Infobar
          title={t('INFOBAR.START')} />

        <Wrapper>
          <div class={cn("row", styles.topRow)}>

            <div class={styles.split}>
              {!customerLoaded &&
                <LoginWidget
                  class={styles.loginWidget}
                  processClass={styles.loginProcess} />
              }

              {customerLoaded &&
                <LogoutWidget
                  class={styles.logoutWidget}
                  processClass={styles.logoutProcess} />
              }
            </div>

            <div class={styles.split}>
              <SearchWidget
                key="frontPage"
                class={styles.searchWidget}
                processClass={styles.searchProcess}
                fieldLabel={t('WIDGET.SEARCH_LABEL_TEXT')}
                onSubmit={pushRoute}/>
            </div>

          </div>

          {searched &&
            <div class="row">
              <SearchResult />
            </div>
          }
        </Wrapper>

        <Helmet title={t('FRONTPAGE.TITLE')}/>

      </div>
    )
  }
}

// @flow @jsx h

import { h, Component }      from "preact";
import { connect }           from "preact-redux";
import { LOADING, loadView } from "store-reducers/view";
import { Redirect }          from "react-router";

import NotFound     from "containers/NotFound";
import ServerError  from "containers/ServerError";
import ProductView  from "containers/ProductView";
import CategoryPage from "containers/CategoryPage";

type Props = {
  location: Location,
  pathname: string,
  loading: boolean,
  redirect: { url: string },
  type: string,
};

@connect(
  state => ({
    hintedUrl: state.view.hintedUrl,
    pathname:  state.view.pathname,
    redirect:  state.view.redirect,
    loading:   state.view.state === LOADING,
    type:      state.view.type,
    view:      state.view,
  }),
  dispatch => ({
    loadView: path => dispatch(loadView(path))
  })
)
export default class RouteView extends Component {
  updateUrl(location: Location, currentPathname: string) {
    if (location.pathname !== currentPathname) {
      this.props.loadView(location.pathname + location.search);
    }
  }

  componentWillMount() {
    this.updateUrl(this.props.location, this.props.pathname);
  }

  componentWillReceiveProps({ location, pathname }: Props) {
    this.updateUrl(location, pathname);
  }

  render({ loading, redirect, type }: Props) {
    switch (type) {
      case "product":  return <ProductView />;
      case "category": return <CategoryPage />;
      case "error":    return <ServerError />;
      case "redirect": return <Redirect to={redirect.url} />;

      default: return loading || !type ? null : <NotFound />;
    }
  }
}

// @flow @jsx h

import { h } from 'preact';
import { PureComponent } from 'shared-components';
import styles from './styles.scss';

type Props = {
  dotClass: string
}

export default class Loader extends PureComponent {
  render({ dotClass }: Props) {
    return (
      <div class={styles.loader}>
        <div class={dotClass}></div>
        <div class={dotClass}></div>
        <div class={dotClass}></div>
      </div>
    )
  }
}

// @flow @jsx h

import type { Node } from 'preact';
import { h } from 'preact';
import { PureComponent } from 'shared-components';
import Button from 'components/Button';
import cn from 'classnames';

import styles from './styles.scss';

type Props = {
  children: Node,
  to: string,
  icon: string,
  iconPosition: string,
  buttonWrapClass: string,
  active: boolean,
}

export default class SidebarNavItem extends PureComponent {
  render({ children, to, icon, iconPosition, buttonWrapClass, active}: Props) {
    return (
      <li>
          <Button
            active={active}
            class={cn(styles.button, buttonWrapClass)}
            activeClassname={styles.active}
            modifiers="wide"
            to={to}
            icon={icon}
            iconPosition={iconPosition}
            iconClass={styles.icon}
            buttonWrapClass={buttonWrapClass}>

            {children}

          </Button>
      </li>
    )
  }
}

// @flow

/**
 * search.js
 * Reducers for searching amoung products
 */

import { jsonResponse } from "fetch-utilities";

export const PENDING = 'store/search/PENDING';
export const LOADING = 'store/search/LOADING';
export const LOADED = 'store/search/LOADED';

export const CLEAR = 'store/search/CLEAR';

const initialState = {
  state: PENDING,
  result: {},
  query: null,
  error: false
}

export default function reducer(state: Object = initialState, action: Object = {}) {
  switch(action.type) {
    case LOADING:
      return {
        ...state,
        state: action.type,
        result: {},
        query: action.query
      };

    case LOADED:
      return {
        ...state,
        state: action.type,
        result: action.payload.error ? state.result : action.payload,
        error: action.payload.error
      };

    case CLEAR:
      return initialState;

    default:
      return state;
  }
}

export const search = (query: string) => (dispatch: Function, getState: Function, client: Function) => {
  if(query === getState().search.query) {
    return;
  }

  dispatch({
    type: LOADING,
    query
  });

  dispatch({
    type: LOADED,
    payload: client(`/search?query=${encodeURI(query)}`).then(jsonResponse).catch(() => ({ error: true})),
    query
  })
}

export const clear = () => (dispatch: Function) => {
  dispatch({
    type: CLEAR
  });
}

// @flow @jsx h

import { h } from 'preact';
import Helmet from "preact-helmet";
import { connect } from 'preact-redux';
import { PureComponent } from "shared-components";
import { push } from "react-router-redux";
import styles from "./styles.scss";
import LoginForm from "components/LoginForm";
import type { TLoginFormData } from "types/loginForm";

import { login, LOGGING_IN } from 'reducers/user';

type Props = {
  login: function,
  loggingIn: boolean,
  loggedIn: boolean
};

@connect(state => ({
  loggingIn: state.user.state === LOGGING_IN,
  loggedIn: state.user.loggedIn,
}), dispatch => ({
  login: x => dispatch(login(x)),
  push: x => dispatch(push(x)),
}))
export default class LoginPage extends PureComponent {

  componentDidUpdate(prevProps: Props) {
    if(this.props.loggedIn) {
      this.props.push('/start')
    }
  }

  submitForm = (formData: TLoginFormData) => {
    this.props.login(formData);
  }

  render(props: Props, _: any, { t }: Context) {
    return (
      <div class={styles.loginPage}>
        <Helmet
          title={t("LOGIN.TITLE")} />
          <LoginForm
            onSubmit={this.submitForm}
            loggingIn={this.props.loggingIn}
            />
      </div>
    )
  }
}
